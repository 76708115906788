import { inject } from 'vue';

export function useStateIcon() {
  const { states } = inject('constants');

  const getTwqPhaseStateIcon = (slug) => {
    switch (slug) {
      case states.COMPLETED:
        return "like-green";
      case states.NEGATIVE_FEEDBACK:
        return "dislike-red";
      case states.MANAGER_FAILED:
        return "manager/faild";
      case states.ISO_FAILED:
      case states.ISO_REASSIGNED:
      case states.ISO_IN_SKIPPED:
      case states.MANAGER_REASSIGNED:
      case states.MANAGER_SKIPPED:
        return "iso-in/reassign";
      case states.ON_HOLD:
        return "consulting/pause";
      case states.ASSIGNED:
      case states.IN_THE_WORKS:
        return "gear-violet";
      case states.PENDING:
        return "clock";
      default:
        return "consulting/question";
    }
  };

  const getPrescriptionTestStateIcon = (slug) => {
    switch (slug) {
      case "pending":
        return "consulting/pending-violet";

      case "without-state":
        return "consulting/question";

      case "in-the-works":
      case "bagged":
      case "packaged":
        return "gear-violet";

      case "manager-changed-test":
      case "iso-in-changed-test":
        return "change-test";

      case "non-complaint":
        return "blocked";

      case states.SHIPPED:
      case states.COMPLETED:
        return "check-circle-fill-green";

      case states.ON_HOLD:
        return "consulting/pause";

      default:
        return "close-circle-fill-red";
    }
  };

  return { getTwqPhaseStateIcon, getPrescriptionTestStateIcon};
}

