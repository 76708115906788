import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader.js";

export async function store(testWorkQueueId, isoSkipped) {
  const axios = axiosInstanceWithoutLoader().primoLab;
  let flag;

  try {
    const response = await axios.post(`/test-work-queue-flags/${testWorkQueueId}`, {
      iso_skipped: isoSkipped
    });
    flag = response.data.data;
  } catch (error) {
    throw error.response.data.errors;
  }

  return { flag };
}
