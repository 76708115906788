<script>
import { onMounted, ref } from "vue";
import { fetchNotes } from "@/use/repositories/prescriptions/fetchNotes";

import Notes from "@/components/Notes.vue";
import Spinner from "@/components/general/Spinner.vue";

export default {
  name: "NoteOverview",
  components: { Notes, Spinner },
  props: {
    prescriptionTest: { type: Object, required: true },
  },
  setup(props) {
    const relativeNotes = ref([]);
    const showSpinner = ref(false);

    const getAllNotes = async () => {
      showSpinner.value = true;
      try {
        const { notes } = await fetchNotes(props.prescriptionTest.prescription_id);

        relativeNotes.value = notes.prescription_tests.find(
          (item) => item.id === props.prescriptionTest.id
        );
      } catch (error) {
        console.log(error);
      } finally {
        showSpinner.value = false;
      }
    };

    onMounted(async () => await getAllNotes());

    return { relativeNotes, showSpinner };
  },
};
</script>

<template>
  <div class="d-flex">
    <div class="rounded-custom bg-home-violet h-fit-content notes-list w-50 p-5 m-5 ">
      <h4 class="m-2 mb-4">Dalla clinica: </h4>
      <div v-if="typeof prescriptionTest.feedback === 'number'" class="d-flex m-2">
        <span class="fw-bold ">Esito Feedback: </span>
        <img
          class="mb-2 ms-2"
          :src="require(`@/assets/icons/${prescriptionTest.feedback ? 'like-green' : 'dislike-red'}.svg`)"
          width="25"
          alt="note-icon" />
      </div>
      <div>
        <div class="m-2">
          <span class="fw-bold">Feedback: </span>
          <span>{{ prescriptionTest.feedback_note ?? "Nessun feedback presente" }}</span>
        </div>
        <hr >
        <div class="m-2">
          <span class="fw-bold me-2">Nota:</span>
          <span>{{ prescriptionTest.notes ?? "Nessuna nota presente" }}</span>
        </div>
      </div>
    </div>
    <div class="notes-list w-50">
      <Spinner v-if="showSpinner" />
      <Notes v-else-if="relativeNotes.laboratory_notes.length" :notes="[relativeNotes]" />
      <div v-else class="d-flex h-100 justify-content-center align-items-center">
        {{ $t('No notes present') }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .notes-list {
      max-height: 70vh;
      overflow-y: auto;
  }
  .h-fit-content {
      height: fit-content;
  }
</style>
