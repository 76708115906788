<script>
import { onMounted, ref, inject } from "vue";
import { useStore } from "vuex";
import { fetchById as fetchPrescriptionTest } from "@/use/repositories/prescriptionTests/fetchById";
import { update as updateTestWorkQueue } from '@/use/repositories/testWorkQueue/update';
import { useInjectParentAndSortByState } from '@/composables/useInjectParentAndSortByState';
import { useStateIcon } from "@/composables/useStateIcon";
import { dateFormatter } from "@/use/utilities/time/dateFormatter";
import Spinner from "@/components/general/Spinner";
import AddIsoPhase from "@/components/Consulting/AddIsoPhase.vue"

import _last from "lodash/last"

export default {
  components: { Spinner, AddIsoPhase },
  props: {
    prescriptionTestId: {
      type: Number,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore();
    const { states, roles } = inject('constants');
    const readonly = inject('readonly');
    const { testWorkQueuePhases, structuredPhases, pushIsoPhase } = useInjectParentAndSortByState();
    const { getTwqPhaseStateIcon } = useStateIcon();
    const showSpinner = ref(false);
    const relativeTest = ref(null);

    const isTwqPhaseAddable = (twqPhase) => {
      return (
        (! twqPhase.parent && twqPhase.state.slug === states.PENDING) ||
        (twqPhase.parent?.slug === states.COMPLETED &&
          twqPhase.state.slug === states.PENDING &&
          store.state.user.roles.includes(roles.MANAGER))
      );
    };

    const areAllPhasesCompleted = () => {
      return (
        testWorkQueuePhases.value.length &&
        testWorkQueuePhases.value.every(item => item.state.slug === states.COMPLETED)
      );
    };

    const canAddIsoPhase = ({ twqPhase = null, checkAllPhases = false } = {}) => {
      if (readonly) return false;
      if (twqPhase) return isTwqPhaseAddable(twqPhase);
      if (checkAllPhases) {
        return (
          props.isCurrent &&
          areAllPhasesCompleted() &&
          relativeTest.value?.state?.slug === 'in-lavorazione'
        );
      }

      return false;
    };


    const updateIsoPhase = (isoPhase, allPhasesComnpleted) => {
      pushIsoPhase(isoPhase, allPhasesComnpleted);

      if (allPhasesComnpleted) {
        updateTestWorkQueue({
          id: isoPhase?.test_work_queue_id,
          state_slug: states.IN_THE_WORKS,
          action: "update_state",
        });
      }
    };

    const fetchHistoryStates = async () => {
      showSpinner.value = true;
      try {
        const { prescriptionTest } = await fetchPrescriptionTest(props.prescriptionTestId, {
          action: "with_all_relations_and_states",
        });
        relativeTest.value = prescriptionTest;
        testWorkQueuePhases.value = prescriptionTest.test_work_queue.test_work_queue_phases;
      } catch (error) {
        console.error(error);
      } finally {
        showSpinner.value = false;
      }
    };

    const lastElementOf = (array) => _last(array);

    onMounted(async () => await fetchHistoryStates());

    return {
      showSpinner,
      getTwqPhaseStateIcon,
      structuredPhases,
      dateFormatter,
      testWorkQueuePhases,
      canAddIsoPhase,
      updateIsoPhase,
      lastElementOf,
      readonly,
      relativeTest
    };
  },
};
</script>
<template>
  <Spinner v-if="showSpinner" />
  <template v-else-if="structuredPhases && structuredPhases.length">
      <AddIsoPhase class="py-4"
        v-if="canAddIsoPhase({ checkAllPhases: true })"
        @push-iso-phase="updateIsoPhase($event, true)"
        :twqPhase="lastElementOf(structuredPhases)" />
    <div v-for="(phase, index) in structuredPhases.reverse()" :key="index">
      <h5 class="text-violet">{{ phase.internal_phase.name }}</h5>
      <ul class="custom-list">
        <li v-for="(state, index) in phase.states.reverse()" :key="index"
          class="d-flex align-items-start flex-column">
          <div class="w-100 d-flex">
            <img
              class="me-2"
              :src="require(`@/assets/icons/${getTwqPhaseStateIcon(state?.slug)}.svg`)"
              width="25"
              alt="check-circle-fill-violet" />
            <div class="d-flex justify-content-between w-100">
              <span>{{ state?.name }} - {{ phase.internal_phase.name }}</span>
              <small class="mb-1 text-muted">
                {{ state?.pivot?.user?.first_name }}
                {{ state?.pivot?.user?.last_name }}
                {{ state?.pivot?.user && '-' }}
                {{ dateFormatter(state?.pivot?.created_at) }}
              </small>
            </div>
          </div>
          <template v-if="state?.pivot?.feedbacks?.length">
            <div class=" ms-5 d-flex w-100 flex-column">
              <div v-for="(feedback, index) in state?.pivot?.feedbacks" :key="index">
                <div
                  class="rounded-custom p-2 mb-2 w-90"
                  :class="{
                    'bg-on-hold': state.slug === 'on-hold',
                    'btn-red': state.slug !== 'on-hold',
                  }">
                  <small class="mb-1">Nota Interna - {{ dateFormatter(feedback.updated_at) }} </small>
                  <template v-if="feedback.pivot?.feedback?.description">
                    <div class="d-flex flex-column">
                      <p>{{ feedback.pivot.feedback.description }}</p>
                      <p>{{ feedback.pivot.note }}</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <p class=" ms-5 text-muted">Nessuna nota</p>
          </template>
        </li>
      </ul>
      <AddIsoPhase class="py-4"
        v-if="canAddIsoPhase({ twqPhase: phase })"
        @push-iso-phase="updateIsoPhase($event, false)"
        :twqPhase="phase" />
    </div>
  </template>
  <p v-else>Nessuno stato</p>
</template>
<style scoped>
  .custom-list {
    list-style: none;
  }

  .custom-list li {
    position: relative;
    padding-left: 2rem;
  }

  .custom-list li::before {
    content: "•";
    color: #725D82;
    font-size: 2rem;
    position: absolute;
    left: 0;
    top: 16%;
    transform: translateY(-50%);
  }
</style>
