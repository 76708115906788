<script>
import { computed, reactive, inject, onMounted } from "vue";
import { hideModal } from "@/use/modal/hide";

import { useStateIcon } from "@/composables/useStateIcon";

import Empty from "@/components/Modal/Empty";
import Accordion from "@/components/general/Accordion";
import UploadNote from "@/components/History/UploadNote.vue";
import PhaseStates from "@/components/History/PhaseStates.vue";
import NoteOverview from "@/components/History/NoteOverview.vue";
import WorkInteractions from "@/components/History/WorkInteractions.vue";

export default {
  emits: ["active"],
  components: { Empty, Accordion, UploadNote, PhaseStates, NoteOverview, WorkInteractions},
  props: {
    prescriptionTest: { type: Object, required: true },
    index: { type: Number, required: true },
    activeId: { default: null }
  },
  setup(props, { emit }) {
    const { getPrescriptionTestStateIcon } = useStateIcon();

    const readonly = inject('readonly');

    const uploadNote = reactive({
      id: `${props.prescriptionTest.id}_upload_note`,
      show: false,
    });

    const notesOverview = reactive({
      id: `${props.prescriptionTest.id}_notes_overview`,
      show: false,
    });

    const collapsed = computed({
      get: () => props.activeId !== props.prescriptionTest.id,
      set: (newActive) => {
        if (! newActive) {
          emit("active", props.prescriptionTest.id);
        } else {
          emit("active", null);
        }
      },
    });

    const hideUploadNote = async () => {
      await hideModal(uploadNote.id);
      uploadNote.show = false;
    }

    onMounted(() => {
      if (props.prescriptionTest.is_current_test) {
        emit("active", props.prescriptionTest.id);
      }
    })

    return {
      getPrescriptionTestStateIcon,
      collapsed,
      uploadNote,
      notesOverview,
      readonly,
      hideUploadNote,
    };
  },
};
</script>

<template>
  <div :class="{ 'preavius-danger': prescriptionTest.previousFeedback === 0 && prescriptionTest.is_current_test}">
    <Accordion
    :id="prescriptionTest.id"
    :background="'bg-white'"
    :collapsed="collapsed"
    :item-style="['border-none']"
    :actions-style="['w-100']"
    v-model:collapsed="collapsed"
    show-arrow >
    <template v-slot:action>
      <div class="w-100 text-dark w-100">
        <div class="d-flex align-items-center mb-1">
          <span class="custom-badge rounded-circle bg-violet my-1 me-3">{{ prescriptionTest.code }}</span>
          <span class="fw-bold me-1"> {{ prescriptionTest.name }}</span>
          <img
            v-if="prescriptionTest.test_work_queue.state.slug"
            :src="require(`@/assets/icons/${getPrescriptionTestStateIcon(prescriptionTest.test_work_queue.state.slug)}.svg`)"
            :alt="`status-${prescriptionTest.test_work_queue.state.slug}`"
            width="25"
            class="mx-3" />
        </div>
        <div class="d-flex align-items-end">
          <WorkInteractions v-if="! readonly" :prescription-test="prescriptionTest" />
          <div
            :class="{'custom-danger': prescriptionTest.previousFeedback === 0 && prescriptionTest.is_current_test}"
            class="d-flex align-items-center bg-light-violet rounded-custom p-3 h-fit-content w-100 ms-3"
            @click="notesOverview.show = true">

            <img
              v-if="typeof prescriptionTest.feedback === 'number'"
              :src="require(`@/assets/icons/${prescriptionTest.feedback ? 'like-green' : 'dislike-red'}.svg`)"
              class="m-2"
              width="25"
              alt="note-icon"
            />
            <div class="w-100">
              <template v-if="prescriptionTest.previousFeedback === 0 && prescriptionTest.is_current_test">
                <span class="d-flex align-items-center">
                  <img :src="require(`@/assets/icons/danger.svg`)" width="30" />
                  <span class="ms-3 text-pastel-red fw-bold">Attenzione la prova precedente è già stata fallita.</span>
                </span>
                <hr>
              </template>
              <span class="d-flex align-items-center m-1 ">
                <span class="fw-bold me-2">Feedback: </span>
                <span> {{ prescriptionTest.feedback_note ?? "Nessun feedback presente" }} </span>
              </span>
              <hr>
              <span class="m-1 ">
                <span class="fw-bold me-2">Nota: </span>
                <span>{{ prescriptionTest.notes ? prescriptionTest.notes.length > 70 ? prescriptionTest.notes.slice(0, 70) + '...' : prescriptionTest.notes :  "Nessuna nota presente" }}</span>

              </span>
            </div>
          </div>
          <button class="btn btn-sm me-auto" @click="openModal">
            <img class="m-2" src="@/assets/icons/note.svg" width="25" alt="note-icon"
              @click="uploadNote.show = true" />
          </button>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <PhaseStates
        v-if="! collapsed"
        :is-current="prescriptionTest.is_current_test"
        :prescription-test-id="prescriptionTest.id" />
    </template>
  </Accordion>
  </div>
  <Empty
    v-if="uploadNote.show"
    :modal_id="uploadNote.id"
    :size="'lg'"
    @hidden="hideUploadNote">
    <div class="p-5">
      <UploadNote
        :prescription-test="prescriptionTest"
        @upload="hideUploadNote"
        @reset="hideUploadNote" />
    </div>
  </Empty>
  <Empty
    v-if="notesOverview.show"
    :modal_id="notesOverview.id"
    :size="'xl'"
    @hidden="notesOverview.show = false">
    <NoteOverview :prescription-test="prescriptionTest" />
  </Empty>
</template>

<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";
  .me-auto {
    margin-left: auto;
  }
  .h-fit-content {
    height: fit-content;
  }
  .note-no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  .custom-badge {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
  }

  .custom-danger {
    border-left: 8px solid $pastel_red;
    border-right: 8px solid $pastel_red;
    border-radius: 1rem;
  }

  .preavius-danger {
    border-left: 5px solid $pastel_red;
  }

  .text-pastel-red {
    color: $pastel_red
  }
</style>
