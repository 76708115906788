import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader.js";

export async function show(stateSlug) {
  const axios = axiosInstanceWithoutLoader().primoLab;
  let state

  await axios
    .get(`/test-work-queue-states/${stateSlug}`)
    .then(async (resp) => state = resp.data.data)
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { state };
}
