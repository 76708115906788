import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader.js";

export async function show(id, action) {
  const axios = axiosInstanceWithoutLoader().primoLab;
  let testWorkQueue;

  await axios
    .get(`/test-work-queues/${id}`, { params: { action } })
    .then(async (resp) => {
      testWorkQueue = resp.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { testWorkQueue };
}
