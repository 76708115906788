<template>

  <template v-if="$props.allIsCompleted || shipTemplate">
    <div class="d-flex w-100 h-100 align-items-center bg-home-violet p-3">
      <div class="d-flex w-25 p-3">
        {{ $props.clinic.clinic_name }}
      </div>
      <div class="d-flex w-75 p-3">
        <div class="d-flex flex-column w-25">
          <label for="shipCode">Codice spedizione</label>
          <input type="text" class="form-control" id="shipCode" v-model="shippingCode" placeholder="Inserisci il codice"
            autofocus :disabled="disabledEditShippingCode">
        </div>
      </div>

      <template v-if="isAutomaticallyCreated && automaticShippingOptions.services.length">
        <div class="d-flex w-25 p-3">
          <Multiselect class="multiselect-violet" :close-on-select="true" :clear-on-select="false"
            placeholder="Scegli il servizio..." v-model="automaticShippingOptions.selected"
            :options="automaticShippingOptions.services" />
        </div>
      </template>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-violet text-uppercase mx-4 me-2"
          @click="printDdt()">Stampa</button>
        <button type="button" class="btn btn-outline-violet text-uppercase" @click="decline()">Annulla</button>
        <button v-if="![shippingProviders.LOCAL, shippingProviders.TURIN_SOUTH].includes(selectedProviderSlug)"
          type="button" class="btn btn-danger text-uppercase mx-4 me-2"
          @click="changeShippingProvider(shippingProviders.LOCAL)">Sped.
          Locale</button>
      </div>

    </div>
  </template>

  <template v-else>
    <div class="d-flex flex-column w-100 h-100 justify-content-center align-items-center bg-home-violet p-3">
      <div class="d-flex flex-column align-items-center text-center">
        <h2>Procedere con la stampa?</h2>
        <p class="w-75">La scatola risulta incompleta: vuoi comunque procedere con la stampa?</p>
        <div class="d-flex">
          <div class="d-flex">
            <button type="button" class="btn btn-violet text-uppercase me-2" @click="proceedAnyway()">Si</button>
            <button type="button" class="btn btn-outline-violet text-uppercase" @click="decline()">No</button>
          </div>
        </div>
      </div>
    </div>
  </template>

</template>

<script>
import { onMounted, ref, watch, computed, reactive, inject } from 'vue';
import moment from 'moment';
import { fetchShippingCodeById } from '@/use/repositories/clinic/fetchShippingCodeById';
import { show } from '@/use/repositories/clinic/show';
import Multiselect from "@vueform/multiselect";
import _ from "lodash";

import { storeTransportDocument } from '@/use/repositories/clinic/storeTransportDocument';
import { attachShippingCode } from '@/use/repositories/testWorkQueue/attachShippingCode';
import { store as storeShippingCode } from '@/use/repositories/shippingCode/store';
import { base64ToPdf } from '@/use/utilities/base64ToPdf';
import { print } from "@/use/repositories/devices/print";

export default {
  name: "Print",
  props: {
    allIsCompleted: {
      type: Boolean,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
    focus: {
      type: Boolean,
      required: true,
    },
    selectedShippingProvider: {
      type: Object,
      required: true,
    },
    packagedPrescriptions: {
      type: Array,
      required: false,
    }
  },
  components: {
    Multiselect,
  },
  emits: [
    "continue",
    "catch",
    "completed"
  ],
  setup(props, { emit }) {
    const shipTemplate = ref(false);
    const selectedProviderSlug = ref(null);
    const shippingCode = ref(null);
    const automaticShippingOptions = reactive({
      services: [],
      selected: undefined,
    });
    const disabledEditShippingCode = ref(false);

    const { shippingProviders, departments, devicesType, states } = inject("constants");


    const baggedTestWorkQueues = computed(() => props.clinic.items.filter(twq => twq.state.slug === states.BAGGED))

    const resetAutomaticShippingOptions = () => {
      automaticShippingOptions.services = [];
      automaticShippingOptions.selected = undefined;
    }

    const changeShippingProvider = (shippingProviderSlug) => {
      selectedProviderSlug.value = shippingProviderSlug;
      resetAutomaticShippingOptions();
      handleAutomaticShippingCodes();
    }

    const handleAutomaticShippingCodes = async () => {
      if (isAutomaticallyCreated.value) {
        try {
          const response = await show(props.clinic.clinic_id, {
            action: `with_${selectedProviderSlug.value.toLowerCase()}_services`,
            appointment_date: minAppointmentDate.value.format("YYYY-MM-DD HH:mm"),
          });

          const services = _.values(response.data.meta.services);

          if (services.length) {
            automaticShippingOptions.services = services.map(item => ({
              value: item.code,
              label: item.description
            }));

            const selectedObject = services.find(service => service.selected);
            automaticShippingOptions.selected = selectedObject ? selectedObject.code : undefined;
          } else {
            disabledEditShippingCode.value = false;
          }
        } catch (error) {
          console.error("Error fetching FedEx services:", error);
        }
      } else if (selectedProviderSlug.value !== shippingProviders.DHL) {
        try {
          const response = await fetchShippingCodeById(props.clinic.clinic_id);
          shippingCode.value = response.code;
          disabledEditShippingCode.value = false;
        } catch (error) {
          console.error("Error fetching shipping code:", error);
        }
      }
    }

    onMounted(async () => {
      // Rewrite selected shipping provider.
      selectedProviderSlug.value = props.selectedShippingProvider.slug;

      // Overwrite flag
      disabledEditShippingCode.value = shippingProviders.LOCAL === selectedProviderSlug.value || isAutomaticallyCreated.value;

      handleAutomaticShippingCodes();
    });

    const isAutomaticallyCreated = computed(() => {
      return [shippingProviders.FEDEX, shippingProviders.DHL].includes(selectedProviderSlug.value);
    });

    const minAppointmentDate = computed(() => {

      return props.packagedPrescriptions.reduce((min, appointment) => {
        const appointmentStart = moment(appointment.prescription_test?.date?.start_at);
        const minStart = moment(min);

        return appointmentStart.isBefore(minStart) ? appointmentStart : minStart;
      }, moment(_.head(props.packagedPrescriptions)?.prescription_test?.date?.start_at));
    });

    watch(() => props.focus, (value) => {
      if (value) {
        shippingCode.value = null;
        const shippingCodeInput = document.getElementById("shipCode");
        shippingCodeInput.focus();
      }
    });

    const decline = () => {
      shipTemplate.value = false;
      emit('continue', false);
    }

    const proceedAnyway = () => {
      shipTemplate.value = true;
    }

    const printDdt = async (code = shippingCode.value) => {
      emit('continue');
      try {
        const { clinic_id: clinicId, clinic_name: clinicName } = props.clinic;
        const { id: shippingProviderId, slug: shippingProviderSlug } = props.selectedShippingProvider;

        // Handle automatic shipping if required
        if ([shippingProviders.FEDEX, shippingProviders.DHL].includes(shippingProviderSlug) && automaticShippingOptions.selected) {
          code = await handleAutomaticShipping(clinicId, shippingProviderId, shippingProviderSlug);
        }

        const shippingCodeId = await storeShippingCodeAndGetId(clinicId, shippingProviderId, code);

        await attachShippingCode(baggedTestWorkQueues.value.map(twq => twq.id), shippingCodeId);

        await printTransportDocument(shippingCodeId);

        console.log(`CLINIC: ${clinicName} ID: ${clinicId} SHIPPED COMPLETED`);
        emit('completed');
      } catch (error) {
        console.error('Error during DDT printing process:', error);
        emit('catch');
      }
    };

    const handleAutomaticShipping = async (clinicId, shippingProviderId, shippingProviderSlug) => {
      const { result: { shippingCode, pdf } } = await storeShippingCode({
        action: `by_${shippingProviderSlug}`,
        shipping_provider_id: shippingProviderId,
        clinic_id: clinicId,
        service_type: automaticShippingOptions.selected,
      });

      base64ToPdf(pdf); // Print the generated PDF
      return shippingCode;
    };

    const storeShippingCodeAndGetId = async (clinicId, shippingProviderId, code) => {
      const { result: { id } } = await storeShippingCode({
        action: 'by_all',
        clinic_id: clinicId,
        shipping_provider_id: shippingProviderId,
        code,
      });

      return id;
    };

    const printTransportDocument = async (shippingCodeId) => {
      const { data: transportDocumentData } = await storeTransportDocument(shippingCodeId);
      await print({
        device_type_slug: devicesType.PRINTER,
        department_slug: departments.LOGISTICS,
        data: transportDocumentData,
      });
    };

    return {
      decline,
      proceedAnyway,
      shipTemplate,
      printDdt,
      shippingCode,
      automaticShippingOptions,
      shippingProviders,
      disabledEditShippingCode,
      changeShippingProvider,
      isAutomaticallyCreated,
      selectedProviderSlug,
    }
  }
}
</script>
