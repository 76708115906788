<script>
import { reactive, inject } from "vue";
import debounce from "lodash/debounce";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { index as fetchTag } from "@/use/repositories/devices/index.js";
import { geolocalize } from "@/use/utilities/devices/geolocalize";
import { searchBy as fetchPrescription } from "@/use/repositories/prescriptions/searchBy";
import { index as fetchAllDigitalWithPrints } from "@/use/repositories/testWorkQueue/index";
import Brand from "@/components/Brand.vue";
import DeviceCommand from "@/components/devices/DeviceCommand.vue";
import LeafletMap from "@/components/devices/LeafletMap.vue";
import ButtonBack from "@/components/general/ButtonBack.vue";
import { useBarCodeValidator } from "@/composables/useBarCodeValidator";
import { print as printAnalogLabel} from "@/use/repositories/devices/print";
import { success } from "@/use/toast/success";
import { error } from "@/use/toast/error";

export default {
  name: "DeviceConsulting",
  components: { Brand, DeviceCommand, LeafletMap, ButtonBack },
  setup() {
    const i18n = useI18n();
    const { validateInput } = useBarCodeValidator();
    const { devicesType, departments } = inject("constants");

    const userMessage = reactive({
      show: false,
      message: ""
    })

    const deviceInfo = reactive({
      searchedCode: null,
      code: null,
      device: null,
      customers: [],
    });

    const activeMapInfo = reactive({
      show: false,
      positions: [],
    });

    const onInput = debounce(async () => {

      let input = deviceInfo.searchedCode.trim();

      if (input.length < 4) {
        // It lets the user skip initial "0".
        if (/^(?!0+$)\d+$/.test(input)) {
          input = input.padStart(4, '0');
          deviceInfo.searchedCode = input;
        }
        else return;
      }

      try {
        resetInfo();
        const allowedTypes = ["device", "number_text"];
        const inputType = validateInput(input);

        if (! allowedTypes.includes(inputType)) return;

        const data = inputType === 'device'
          ? await handleDeviceSearch(input)
          : await handlePrescriptionSearch(input);

        if (data?.length) {
          const deviceId = _.head(data).id;
          const tagResponse = await fetchTag('by_ids_with_customer', [deviceId], 'ids');
          updateDeviceInfo(tagResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
    }, 1000);

    const handleDeviceSearch = async (input) => {
      const result = await fetchTag("by_name", input, "name_term");
      const data = result.data;

      if (! data.length) {
        showMessage("Tag not found, try again");
        return null;
      }

      return data;
    };

    const handlePrescriptionSearch = async (input) => {
      const result = await fetchPrescription(input, "by_number_text_with_devices", 1);
      const prescriptions = result.prescriptions.data.data;

      if (! prescriptions.length) {
        showMessage("No prescriptions found");
        return null;
      }

      const devices = _.head(prescriptions).devices;

      if (! devices.length) {
        showMessage("This prescription does not have an associated tag.");
        return null;
      }

      return devices;
    };

    const updateDeviceInfo = (devices) => {
      const device = _.head(devices);
      deviceInfo.device = {
        ...device,
        extra_data: {
          ...device.extra_data,
          area: geolocalize(device.extra_data?.x, device.extra_data?.y),
        },
      };
      deviceInfo.customers = device.prescription_tests.length ? transformdeviceInfo(device) : [];
      activeMapInfo.positions = transformedDevices(devices);
      activeMapInfo.show = true;
    };

    const showMessage = (messageKey) => {
      userMessage.show = true;
      userMessage.message = i18n.t(messageKey);
    };


    const transformedDevices = (devices) => {
      return devices.map((device) => ({
        device_id: device.id,
        device_name: device.name,
        area: geolocalize(device.extra_data?.x, device.extra_data?.y),
        x: device.extra_data?.x,
        y: device.extra_data?.y,
        prescriptions: device.prescription_tests?.map((test) => test.prescription?.number_text) || [],
      }));
    };

    const transformdeviceInfo = (data) => {
      return _(data.prescription_tests)
        .groupBy((test) => test.prescription.customer.id)
        .map((tests) => {
          const customer = _.head(tests).prescription.customer
          return {
            customer: {
              id: customer.id,
              first_name: customer.first_name,
              last_name: customer.last_name,
              bruxism: customer.bruxism,
            },
            prescriptions: _.map(
              _.groupBy(tests, "prescription_id"),
              (groupedTests, prescriptionId) => ({
                prescription_id: parseInt(prescriptionId),
                prescription: _.head(groupedTests).prescription,
                tests: groupedTests,
                test_work_queue_id: _.head(groupedTests).test_work_queue?.id || null,
                test_work_queue_prints: _.head(groupedTests).test_work_queue?.prints || [],
              })
            ),
          };
        })
        .value();
    };

    const resetInfo = () => {
      activeMapInfo.show = false;
      activeMapInfo.positions = [];
      deviceInfo.device = null;
      deviceInfo.customers = [];
      userMessage.show = false;
    }

    const pushPrint = (response, twqId) => {
      const customer = _.find(deviceInfo.customers, (cust) =>
        _.some(cust.prescriptions, (pres) => pres.test_work_queue_id === twqId)
      );
      if (customer) {
        const prescription = _.find(customer.prescriptions, (pres) => pres.test_work_queue_id === twqId);
        if (prescription) {
          prescription.test_work_queue_prints.push(_.head(response));
        }
      }
    };

    const printLabel = async (twqId) => {
       fetchAllDigitalWithPrints({
      action: 'with_base_64_labels',
      ids: [
        twqId,
      ]
      }, true)
      .then((response) => printAnalogLabel({
        device_type_slug: devicesType.ANALOG_LABEL,
        department_slug: departments.CAM,
        data: _.head(response.testWorkQueue).raw_data,
      }))
      .then((response) => {
        success(i18n.t('Barcodes print successfully!'));
        pushPrint(response, twqId);
      })
      .catch(() => {
        error(i18n.t('An error has occurred. Contact the technical service!'));
      });
    }

    const isPrinted = (prescription) => {
      return prescription.test_work_queue_prints.length > 0;
    };

    return { onInput, deviceInfo, activeMapInfo, userMessage, printLabel, isPrinted };
  },
    };
</script>

<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="d-flex align-items-center w-100">
      <ButtonBack class="ms-4" label="indietro" />
      <div class="m-auto">
        <Brand :width="120" :height="120" :withChat="false" />
      </div>
    </div>
    <div class="container">
      <div class="w-100 p-2">
        <div class="m-auto col-xs-12 col-sm-6">
          <div class="input-group mb-3">
            <input
              v-model="deviceInfo.searchedCode"
              @input="onInput"
              :disabled="$props.disabled"
              ref="input"
              type="text"
              class="form-control rounded-custom p-3"
              placeholder="Codice Tag"
              aria-label="Codice Tag"
            />
          </div>
        </div>

        <div class="row justify-content-center align-items-stretch">
          <div class="col-xs-12 col-sm-6 col-md-4 d-flex flex-column">
            <div v-if="deviceInfo.device" class="card rounded-custom shadow p-2 my-2 shadow flex-fill">
              <div class="card-body d-flex justify-content-between flex-wrap">
                <img src="@/assets/icons/digital-label.svg" width="80" />
                <div class="d-flex flex-column">
                  <span>
                    <span class="fs-6 me-1">Codice:</span>
                    <span class="fw-bold fs-6">{{ deviceInfo.device?.name }}</span>
                  </span>
                  <hr />
                  <span>
                    <span class="fs-6 me-1">Dipartimento:</span>
                    <span class="fw-bold fs-6">{{ deviceInfo.device?.extra_data?.area }}</span>
                  </span>
                  <hr />
                  <span>
                    <span class="fs-6 me-1">Batteria:</span>
                    <span class="fw-bold fs-6">{{ deviceInfo.device?.extra_data?.battery }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="userMessage.show" class="m-auto text-center">
            <div class="card rounded-custom flex-fill shadow my-2 p-4">
              <h4 class="mb-1">{{ userMessage.message }}</h4>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 d-flex flex-column">
            <div v-if="deviceInfo.device?.prescription_tests?.length"
              class="card rounded-custom flex-fill shadow my-2 p-4">
              <h5 class="mb-1">Prescrizioni associate:</h5>
              <ul class="list-group list-group-flush">
                <li v-for="customer in deviceInfo.customers" :key="customer.id" class="list-group-item">
                  <strong>{{ `${customer.customer.first_name} ${customer.customer.last_name}` }}</strong>
                  <div class="list-group mt-1">
                    <div v-for="prescription in customer.prescriptions" :key="prescription.prescription_id"
                      class="list-group-item d-flex flex-column">
                      <a href="#" class="list-group-item-action d-flex justify-content-center align-items-center">
                        <img src="@/assets/icons/prescription.svg" class="me-2" width="20" height="20" />
                        {{ prescription.prescription.number_text }}
                        <img v-if="isPrinted(prescription)" src="@/assets/icons/check-circle.svg" class="ms-2" width="20" height="20" alt="printed" />
                      </a>
                      <button type="button" class="btn btn-outline-violet text-uppercase my-2"
                        @click="printLabel(prescription.test_work_queue_id)">
                        <img class="me-2" src="@/assets/icons/printer.svg" width="25" alt="print label" />
                        stampa etichetta
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 d-flex flex-column">
            <DeviceCommand class="card rounded-custom shadow my-2 flex-fill" v-if="deviceInfo.device"
              :data="deviceInfo.device?.id" />
          </div>
        </div>
        <LeafletMap v-if="activeMapInfo.show" class="rounded-custom w-100 my-2 shadow"
          :positions="activeMapInfo.positions" />
      </div>
    </div>
  </div>
</template>
<style scoped>
:deep(.leaflet-map) {
  border-radius: 1rem !important;
}

</style>
