<script>
import { searchBy as searchByNumberTextAndCustomer } from "@/use/repositories/prescriptions/searchBy";
import { onIntersect } from "@/composables/onIntersect";
import { dateFormatter } from "@/use/utilities/time/dateFormatter";

import Spinner from "@/components/general/Spinner.vue";
import debounce from "lodash/debounce";

import { reactive, ref, watch, onMounted } from "vue";

export default {
  name: "Searchbar",
  emits: ["select"],
  components: { Spinner },
  setup() {
    const input = ref();
    const searchQuery = ref("");
    const searchResults = ref([]);
    const loading = ref(false);
    const error = ref(null);
    const observer = ref({});
    const scrollRef = ref({});
    const meta = reactive({
      page: 1,
      lastPage: null,
    });

    const search = debounce(async () => {
      meta.page = 1;
      meta.lastPage = null;
      searchResults.value = [];
      fetchResults();
    }, 1000);

    const fetchResults = async () => {
      if (!searchQuery.value.trim() || meta.page === meta.lastPage) return;

      loading.value = true;
      searchByNumberTextAndCustomer(searchQuery.value, "by_customer", meta.page)
        .then((response) => {
          const { prescriptions } = response;
          meta.lastPage = prescriptions.data.meta.last_page;

          searchResults.value = searchResults.value.concat(prescriptions.data.data);

          loading.value = false;

          if (meta.page < meta.lastPage) meta.page++;
        })
        .catch(() => {
          error.value = "Errore";
          loading.value = false;
        });
    };

    const scanBarcode = () => {
      input.value.focus();
    };

    const noResult = () => {
      return (
        searchQuery.value.trim() && !loading.value && !error.value && !searchResults.value.length
      );
    };

    watch(
      () => scrollRef.value,
      (el) => (observer.value = onIntersect(el, fetchResults, false, { threshold: 0.1 }))
    );

    onMounted(() => scanBarcode());

    return {
      searchQuery,
      searchResults,
      loading,
      error,
      search,
      scrollRef,
      noResult,
      input,
      dateFormatter,
    };
  },
};
</script>

<template>
  <div class="mt-3">
    <input
      ref="input"
      v-model="searchQuery"
      @input="search"
      type="text"
      class="form-control rounded-custom p-3"
      placeholder="Cerca..."
    />
    <div class="list-group overflow-auto border-violet rounded-custom mt-2 bg-white">
      <button
        @click="$emit('select', result)"
        v-for="(result, index) in searchResults"
        :key="index"
        type="button"
        class="list-group-item list-group-item-action d-flex justify-content-between p-3"
        aria-current="true"
      >
        <span>
          <img src="@/assets/icons/prescription.svg" width="20" height="20" />
          <span class="ms-4 text-uppercase">{{
            ` ${result.number_text} - ${result.customer.last_name} ${result.customer.first_name} (${result.list_row?.name})`
          }}</span>
        </span>
        <span
          >Creata il:
          {{
            dateFormatter(result.creation_date, {
              onlyDate: true,
              onlyTime: false,
              dash: false,
              iso: false,
            })
          }}</span
        >
      </button>
      <section ref="scrollRef"></section>
      <Spinner v-if="loading" class="my-3 w-50" />
      <div v-if="error" class="my-3 text-danger">Errore nella richiesta</div>
      <div v-if="noResult()" class="my-3">Nessun risultato trovato.</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

.list-group {
  max-height: 50vh;
  border: 1px solid $rgb_violet !important;
}
</style>
