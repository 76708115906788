<script>
import { ref, computed, inject } from "vue";
import Print from "@/components/checkout/Print.vue";
import CheckoutTableRow from "@/components/CheckoutTableRow.vue"
export default {
  props: {
    group: { type: Object },
    isFailed: { type: Function },
    filters: { type: Object },
    colspan: { type: Number },
  },
  components: { Print, CheckoutTableRow },
  setup(props) {
    const { states } = inject('constants');

    const showPrint = ref(false);

    const baggedPrescriptions = computed(() => props.group.items.filter(item => item.state.slug === states.BAGGED));

    const showPrintButton = computed(() => !! baggedPrescriptions.value.length);

    return { showPrint, showPrintButton, baggedPrescriptions }
  }
};
</script>

<template>
  <tr v-if="showPrint">
    <td :colspan="$props.colspan">
      <Print
        focus
        :allIsCompleted="false"
        :clinic="group"
        :selectedShippingProvider="filters.shippingProvider"
        :packagedPrescriptions="baggedPrescriptions"
        @continue="() => showPrint = false"
        @catch="() => showPrint = false"
        @completed="() => showPrint = false" />
    </td>
  </tr>
  <CheckoutTableRow
    v-for="(testWorkQueue, index) in $props.group.items"
    :key="testWorkQueue.id"
    :test-work-queue="testWorkQueue"
    :clinic="group"
    :group-length="$props.group.items.length"
    :index="index"
    :is-failed="$props.isFailed"
    :filters="$props.filters"
    :print-button="showPrintButton"
    @print="() => showPrint = true" />
</template>

<style scoped>

</style>
