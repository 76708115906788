import { ref } from 'vue';
import { index as fetchAllTesWorkQueue } from '@/use/repositories/testWorkQueue/index';
import { show as showTestWorkQueue } from "@/use/repositories/testWorkQueue/show";
import _ from "lodash";

const testWorkQueues = ref([]);

const fetchTestWorkQueues = async (shippingProvider) => {
  try {
    const { testWorkQueue: { data } } = await fetchAllTesWorkQueue({
      action: 'with_packaging_checkout',
      shipping_provider_id: shippingProvider.id,
    });
    testWorkQueues.value = data;
    } catch (error) {
      console.error(error);
    }
};

const handleIncomingTestWorkQueue = async (testWorkQueueId, shippingProvider) => {
  try {
    const { testWorkQueue } = await showTestWorkQueue(testWorkQueueId, 'with_actual_department');

    if (
      testWorkQueue &&
      testWorkQueue.prescription_test?.prescription?.delivery_clinic?.shippingProvider?.id === shippingProvider?.id
    ) {
      const existingTestWorkQueue = _.find(testWorkQueues.value, item => testWorkQueueId === item.id);

      if (existingTestWorkQueue) Object.assign(existingTestWorkQueue, testWorkQueue);
      else testWorkQueues.value.push(testWorkQueue);
    }
  } catch (error) {
    console.error(error);
  }
}

const updateTestWorkQueueState = async (testWorkQueueId, state) => {
  const testWorkQueue = _.find(testWorkQueues.value, item => item.id === testWorkQueueId);
  Object.assign(testWorkQueue.state, state);
};

const groupTestWorkQueues = (searchQuery) => {
  const normalizedSearchQuery = searchQuery.toLowerCase();
  const matchesSearchQuery = (str) => str && str.toLowerCase().includes(normalizedSearchQuery);

  return _(testWorkQueues.value)
    .filter(({ prescription_test: { prescription }, state }) => {
      const { delivery_clinic, customer, number_text } = prescription || {};
      const { first_name, last_name } = customer || {};
      console.log('STATE SLUG !!!!!!!', state);
      return (
        state.slug !== 'shipped' &&
        (
          matchesSearchQuery(delivery_clinic?.name) ||
          matchesSearchQuery(last_name) ||
          matchesSearchQuery(first_name) ||
          matchesSearchQuery(number_text)
        )
      );
    })
    .groupBy(item => item.prescription_test.prescription.delivery_clinic.id)
    .map((items, clinicId) => ({
      id: clinicId,
      clinic_id: clinicId,
      name: _.head(items).prescription_test.prescription.delivery_clinic.name,
      items: _.orderBy(
        items.map(item => _.omit(item, ['prescription_test.prescription.delivery_clinic'])),
        ['delivery_date'],
        ['asc']
      )
    }))
    .orderBy(['name'], ['asc'])
    .value();
};

const isFailed = (state) => ['iso-failed', 'manager-failed'].includes(state.slug);

export default function useCheckoutData(filters) {
  return {
    testWorkQueues,
    isFailed,
    handleIncomingTestWorkQueue,
    updateTestWorkQueueState,
    groupTestWorkQueues: () => groupTestWorkQueues(filters.searchQuery),
    fetchTestWorkQueues: () => fetchTestWorkQueues(filters.shippingProvider)
  };
}
