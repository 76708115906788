<script>
import { inject, reactive, ref } from "vue";

import _head from "lodash/head";

import { useI18n } from "vue-i18n";
import { success } from "@/use/toast/success";
import { isCurrentDepartment } from "@/use/utilities/isCurrentDepartment";
import { print } from "@/use/repositories/devices/print";
import { signedUrl } from "@/use/repositories/media/signedUrl";
import { index as fetchAllTesWorkQueue } from "@/use/repositories/testWorkQueue/index";

import ShippingCode from "@/components/Consulting/ShippingCode.vue";

import Empty from "@/components/Modal/Empty";
import VideoPlayer from "@/components/checkout/VideoPlayer";

export default {
  name: "HistoryActions",
  components: { ShippingCode, Empty, VideoPlayer },
  props: {
    prescriptionTest: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { states, devicesType, departments } = inject("constants");
    const i18n = useI18n();
    const dataVideo = reactive({ url: null });
    const showVideoModal = ref(false);

    const printBarcode = async () => {
      try {
        const response = await fetchAllTesWorkQueue(
          {
            action: "with_base_64_labels",
            ids: [props.prescriptionTest.test_work_queue.id],
          },
          true
        );

        await print({
          device_type_slug: devicesType.ANALOG_LABEL,
          department_slug: departments.LOGISTICS,
          data: _head(response.testWorkQueue).raw_data,
        });

        success(i18n.t("Barcodes print successfully!"));
      } catch (error) {
        console.error(error);
      }
    };

    const showVideo = async (media) => {
      try {
        const { url } = await signedUrl(media.uuid)
        dataVideo.url = url;
        showVideoModal.value = true;

      } catch (error) {
        console.error(error)
      }
    };

    return {
      states,
      isCurrentDepartment,
      departments,
      showVideoModal,
      dataVideo,
      showVideo,
      printBarcode,
    };
  },
};
</script>
<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <template v-if="prescriptionTest.shipping_codes.length">
        <ShippingCode
          v-for="(shippingCode, index) in prescriptionTest.shipping_codes"
          :key="index"
          :shipping-code="shippingCode"
          icon-only
        />
      </template>
      <template v-if="prescriptionTest.media?.length">
        <div class="d-flex align-items-center me-2">
          <span
            @click="showVideo(prescriptionTest.media[0])"
            class="material-icons-outlined me-2 md-24">
            play_circle
          </span>
        </div>
      </template>
      <template v-if="isCurrentDepartment(departments.LOGISTICS) && prescriptionTest.is_current_test">
        <div class="d-flex align-items-center me-2">
          <img
            class="me-2"
            src="@/assets/icons/barcode.svg"
            width="25"
            alt="check-circle-fill-green" />
          <small class="text-decoration-underline text-nowrap" @click="printBarcode()">{{ $t('print label') }}</small>
        </div>
      </template>
    </div>
    <div class="d-flex flex-column">
      <template v-if="[states.SHIPPED, states.NEGATIVE_FEEDBACK].includes(prescriptionTest.state_slug)">
        <div class="test-info d-flex flex-wrap mt-1">
          <small class="d-flex align-items-center me-2 text-nowrap">
            <span class="material-icons-outlined me-2">local_shipping</span>
            <span>{{ prescriptionTest.checkout_date }}</span>
          </small>
        </div>
      </template>
      <template v-if="prescriptionTest.delivery_date">
        <small class="d-flex align-items-center me-2 text-nowrap">
          <span class="material-icons-outlined me-2"> flag </span>
          {{ prescriptionTest.delivery_date }}
        </small>
      </template>
      <template v-if="prescriptionTest.created_at">
        <small class="d-flex align-items-center me-2 text-nowrap">
          <span class="material-icons-outlined me-2"> qr_code_scanner </span>
          {{ prescriptionTest.created_at ?? "" }}
        </small>
      </template>
      <div class="d-none">{{ prescriptionTest.id }}</div>
    </div>

    <!-- Video modal -->
    <Empty
      v-if="showVideoModal"
      :size="'xl'"
      :modal_id="`${prescriptionTest?.id}-video-modal`"
      @hidden="() => showVideoModal = false" >
      <VideoPlayer
        :options="{
          autoplay: true,
          controls: true,
          fluid: true,
          sources: [{ src: dataVideo.url, type: 'video/mp4' }],
        } " />
    </Empty>
  </div>
</template>
