export { default as Login } from "./Login";
export { default as PageNotFound } from "./PageNotFound";
export { default as Home } from "./Home";
export { default as Logistica } from "./Logistica";
export { default as CheckinLogistica } from "./checkin/Logistica";
export { default as CheckoutLogistica } from "./Checkout";
export { default as CheckoutPackaging } from "./CheckoutPackaging.vue";
export { default as CheckinTechnician } from "./checkin/Default";

// Plaster
export { default as PlasterCheckinCasting } from "./checkin/plaster/Casting";
export { default as PlasterCheckinFinish } from "./checkin/plaster/Finish";

export { default as RecoverPassword } from "./RecoverPassword";
export { default as ResetPassword } from "./ResetPassword";
export { default as ExpiredPassword } from "./ExpiredPassword";
export { default as Dashboard } from "./Dashboard";
export { default as Logout } from "./Logout";
export { default as NegativeFeedback } from "./NegativeFeedback";
export { default as Feedback } from "./Feedback";
export { default as IsoInState } from "./iso-in/IsoInState";
export { default as PlasterInTheWorks } from "./plaster/InTheWorksJobs";
export { default as CheckinCam } from "./cam/Checkin";
export { default as CamInTheWorks } from "./cam/Dashboard";
export { default as CamSummary } from "./cam/Summary";
export { default as WarehouseDashboard } from "./warehouse/Dashboard";
export { default as ConsultingSearch } from "./consulting/Search";
export { default as ExternalSummary } from "./logistic/external/Summary";
export { default as ConsultingDashboard } from "./consulting/Dashboard";
export { default as Profile } from './Profile';
export { default as RejectedApproval } from './RejectedApproval';
export { default as DigitalLabels } from './DigitalLabels';
export { default as ShippingCodes } from './ShippingCodes';
export { default as Tests } from "./Tests";

export { default as DeviceConsulting } from "./device/DeviceConsulting";

// Manager
export { default as ManagerDashboard } from "./manager/Dashboard";
export { default as Summary } from "@/views/manager/Summary";

// Roles
export { default as LoginChoose } from "./LoginChoose";

// External page
export { default as ExternalDevice } from "./ExternalDevice";

export { default as TwoFactorAuth } from "./TwoFactorAuth";
export { default as Sso } from "./Sso";

