import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader.js";

export async function show(testWorkQueueId) {
  const axios = axiosInstanceWithoutLoader().primoLab;
  let flag = null;

  try {
    const response = await axios.get(`/test-work-queue-flags/${testWorkQueueId}`);
    flag = response.data || null; 
  } catch (error) {
    if (error.response && error.response.status === 404) {
      flag = null;
    } else {
      throw error.response && error.response.data && error.response.data.errors
        ? error.response.data.errors
        : new Error('Errore API non specificato');
    }
  }

  return { flag };
}