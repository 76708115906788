<script setup>
import { onMounted, reactive, ref, watch } from "vue";
import { fetchAll as fetchAllShippingProviders } from "@/use/repositories/shippingProvider/fetchAll";
import _head from "lodash/head";
import CheckoutTable from "@/components/CheckoutTable.vue";
import GlobalSearch from "@/components/GlobalSearch";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const showInfo = ref(false);

const filters = reactive({
  shippingProviders: [],
  shippingProvider: null,
  searchQuery: "",
  resetSearchQuery: false,
});


watch(
  () => filters.shippingProvider,
  () => router.push({
    name: "CheckoutPackaging",
    query: { shipping_provider_id: filters.shippingProvider?.id },
  })
);

onMounted(async () => {
  try {
    const { shippingProviders: fetchedShippingProviders } = await fetchAllShippingProviders();
    filters.shippingProviders = fetchedShippingProviders;
    filters.shippingProvider =
      route.query.shipping_provider_id ?
      filters.shippingProviders.find((item) => item.id === parseInt(route.query.shipping_provider_id))
      : _head(filters.shippingProviders);
  } catch (error) {
    console.error(error);
  }
});
</script>

<template>
  <div
    v-if="showInfo"
    class="alert alert-primary d-flex flex-column m-3 px-4 rounded-custom" >
    <span>
      <img src="@/assets/icons/clock.svg" width="20" alt="clock">
      {{ $t("The work has been completed and is waiting to be bagged") }}
    </span>
    <span>
      <img src="@/assets/icons/upload-video.svg" width="20" alt="upload-vide">
      {{ $t("The work has been bagged") }}
    </span>
    <span>
      <img src="@/assets/icons/check.svg" width="21" alt="check">
      {{ $t("The work has been packaged") }}
    </span>
    <span class="d-flex">
      <div class="alert alert-danger p-0 me-1" role="alert" style="width: 20px; height: 20px;">
      </div>
      {{ $t("The work has been failed") }}
    </span>
  </div>
  <div class="card rounded-custom m-3">
    <div class="container-fluid p-3">
      <div class="p-3">
        <div class="d-flex align-items-center py-3">
          <GlobalSearch
            class="flex-grow-1"
            :label="$t('Search for jobs')"
            :placeholder="$t('Search by clinic, prescription or customer...')"
            :reset="filters.resetSearchQuery"
            @toSearch="(value) => filters.searchQuery = value" />
          <div class="d-flex px-3">
            <select v-model="filters.shippingProvider" class="form-select w-auto me-2 rounded-custom">
              <option
                v-for="shippingProvider in filters.shippingProviders"
                :key="shippingProvider.id"
                :value="shippingProvider"
              >
                {{ shippingProvider.name }}
              </option>
            </select>
          </div>
          <img
            @click="() => showInfo = ! showInfo"
            src="@/assets/icons/box-seam.svg"
            width="50"
            alt="bx-seam"
            class="mx-4 cp" />
        </div>
      </div>
      <CheckoutTable v-if="filters.shippingProvider" :filters="filters" />
    </div>
  </div>
</template>
