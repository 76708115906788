export default {
  install: (app) => {
    const constants = {
      states: {
        PENDING: "pending",
        IN_THE_WORKS: "in-the-works",
        NEGATIVE_FEEDBACK: "negative-feedback",
        COMPLETED: "completed",
        ON_HOLD: "on-hold",
        ASSIGNED: "assigned",
        SITU: "situ",
        ISO_CUSTOM: "iso-custom",
        ISO_FAILED: "iso-failed",
        ISO_IN_CHANGED_TEST: "iso-in-changed-test",
        ISO_IN_CHANGED_DATE: "iso-in-changed-date",
        ISO_IN_SKIPPED: "iso-in-skipped",
        ISO_REASSIGNED: "iso-reassigned",
        ISO_IN_CHOOSE_FORK: "iso-in-choose-fork",
        ISO_CHANGED_DEFAULT_FLOW: "iso-changed-default-flow",
        BAGGED: "bagged",
        PACKAGED: "packaged",
        SHIPPED: "shipped",
        SENT_TO_EXTERNAL: "sent-to-external",
        MANAGER_FAILED: "manager-failed",
        MANAGER_CHANGED_TEST: "manager-changed-test",
        MANAGER_REASSIGNED: "manager-reassigned",
        MANAGER_SKIPPED: "manager-skipped",
      },
      prescriptionTestStates: {
        DA_INVIARE: "da-inviare",
        INVIATA_AL_LAB: "inviata-al-lab",
        IN_LAVORAZIONE: "in-lavorazione",
        FALLITA: "fallita",
        SALTATA: "saltata",
        COMPLETATA: "completata",
        REJECTED: "rejected",
        FAILED_LAB: "failed-lab",
        SUSPENDED_LAB: "suspended-lab",
        CHANGED_TEST_LAB: "changed-test-lab",
        WITH_DATE: "with-date",
      },
      departments: {
        ISO_MOBILE: "iso-mobile",
        ISO_FISSA: "iso-fissa",
        ISO_PROGETTAZIONE: "iso-progettazione",
        ISO_PREPARAZIONE: "iso-preparazione",
        MOBILE: "mobile",
        SCANSIONE: "scansione",
        FINITURA: "finitura",
        PREPARAZIONE: "preparazione",
        CAM: "cam",
        PROGETTAZIONE: "progettazione",
        AMMINISTRAZIONE: "amministrazione",
        LOGISTICS: "logistica",
      },
      internalPhases: {
        FRESAGGIO: 'fresaggio'
      },
      userStates: {
        AVAILABLE: 'available',
        WORKING: 'working',
      },
      roles: {
        TECHNICIAN: 'technician',
        VENDOR: 'vendor',
        MANAGER: 'manager',
      },
      materials: {
        PRINTED_RESIN : "resina-stampata",
        PLASTIC : "plastica",
      },
      assetsType: {
        LOWER_ARCH: "lower-arch",
        UPPER_ARCH: "upper-arch",
        GUM: "gum",
        STUMP: "stump",
        MILLING: "milling",
        CUSTOM_PIN: "custom-pin",
      },
      devicesType: {
        ANALOG_LABEL: 'analog-label',
        DIGITAL_LABEL: 'digital-label',
        PRINTER: 'printer',
      },
      tagColors: {
        BLUE: "blue",
        GREEN: "green",
        CYAN: "cyan",
        RED: "red",
        MAGENTA: "magenta",
        YELLOW: "yellow",
        WHITE: "white",
      },
      shippingProviders: {
        LOCAL: "locale",
        FEDEX: "fedex",
        DHL: "dhl",
        LOMBARDIA: "lombardia",
        TURIN_SOUTH: "torino-sud",
      },
    };

    app.provide("constants", constants);
  },
};
