<template>
  <Checkin
    :label="'Rifinitura'"
    role="postazione-finitura-gesso"
  />
</template>

<script>
import Checkin from '@/components/plaster/Checkin';

export default {
  name: "Finish",
  components: {
    Checkin,
  }
}
</script>
