<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

import { storeNote as storePrescriptionTestNote } from "@/use/repositories/prescriptionTests/storeNote";
import { assignUrl as assignUrlPrescriptionTests } from "@/use/repositories/prescriptionTests/assignUrl";

import Dropzone from "@/components/upload/Dropzone";

export default {
  components: { Dropzone },
  emits: ["upload", "reset"],
  props: {
    prescriptionTest: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();
    const note = ref("");
    const upload = ref(false);
    const mediaCounter = ref(0);

    const hasMedia = computed(() => mediaCounter.value > 0);

    const canSave = computed(() => note.value.trim().length);

    const formData = computed(() => {
      const formData = new FormData();
      formData.append("prescription_test_id", parseInt(props.prescriptionTest.id));
      formData.append("action", hasMedia.value ? "with_media" : "without_media");

      if (canSave.value) formData.append("note", note.value);
      return formData;
    });

    const storeNote = async () => {
      if (hasMedia.value) {
        upload.value = true;
      } else if (canSave.value) {
        await storePrescriptionTestNote(formData.value);
        store.dispatch("setManagerNotesHaveChanged", true);
        reset();
      }
    };

    const restore = () => {
      store.dispatch("setManagerNotesHaveChanged", true);
      reset();
    };

    const reset = async () => {
      emit("reset");
      upload.value = false;
      note.value = "";
      mediaCounter.value = 0;
    }

    return {
      note,
      upload,
      mediaCounter,
      assignUrlPrescriptionTests,
      storePrescriptionTestNote,
      formData,
      reset,
      storeNote,
      restore
    };
  },
};
</script>

<template>
  <Dropzone
    :upload="upload"
    :assign-url-s3="assignUrlPrescriptionTests"
    :store-url="storePrescriptionTestNote"
    :form-data="formData"
    @increment="mediaCounter++"
    @decrease="mediaCounter--"
    @restore="restore"
  />
  <textarea v-model="note" class="form-control" rows="10"> </textarea>

  <div class="modal-footer p-1 border-0 justify-content-center">
    <button
      type="button"
      class="btn btn-outline-violet text-uppercase mt-4"
      @click="storeNote()"
      :disabled="!note.length">
      {{ $t('save') }}
    </button>
    <button
      type="button"
      class="btn btn-outline-violet text-uppercase mt-4"
      @click="reset()">
      {{ $t('close') }}
    </button>
  </div>
</template>
