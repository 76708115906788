import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader";

export async function me(loading = true) {
  const axios = loading ? axiosInstance().primoLab : axiosInstanceWithoutLoader().primoLab;
  let data = [];

  await axios
    .get("/auth/me")
    .then(async (response) => {
      data = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
