<script setup>
import { defineProps, provide, ref, computed } from "vue";
import Work from "@/components/History/Work.vue";

const props = defineProps({
  prescriptionTests: { type: Array, default: () => [] },
  readonly: { type: Boolean, default: false }
});

const activeId = ref(null);

const setActive = (id) => {
  activeId.value = id === activeId.value ? null : id;
};

const mappedPrescriptionTests = computed(() =>
  props.prescriptionTests.map((prescriptionTest, index) => {
    const previousFeedback = index < props.prescriptionTests.length - 1
      ? props.prescriptionTests[index + 1].feedback
      : null;

    return { ...prescriptionTest, previousFeedback };
  })
);

provide("readonly", props.readonly);
</script>

<template>
  <div class="w-100">
    <Work
      v-for="(prescriptionTest, index) in mappedPrescriptionTests"
      :key="prescriptionTest.id"
      :prescription-test="prescriptionTest"
      :index="index"
      :active-id="activeId"
      @active="setActive"
    />
  </div>
</template>
