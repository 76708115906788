<script>
import { computed, inject } from "vue"
import { dateFormatter } from '@/use/utilities/time/dateFormatter';
import { goToConsulting } from "@/use/utilities/goToConsulting";

export default {
  props: {
    testWorkQueue: { type: Object },
    clinic: { type: Object },
    groupLength: { type: Number },
    index: { type: Number },
    isFailed: { type: Function },
    filters: { type: Object },
    printButton: { type: Boolean },
  },
  emits: ['print'],
  setup(props, ) {
    const { states } = inject('constants');
    const isRowExpired = computed(() => props.isFailed(props.testWorkQueue?.state));

    const getStatusImage = computed(() => {
      switch(props.testWorkQueue?.state?.slug) {
        case 'bagged':
          return require('@/assets/icons/upload-video.svg');
        case 'completed':
          return require('@/assets/icons/clock.svg');
        case 'packaged':
          return require('@/assets/icons/check.svg');
        default:
          return '';
      }
    });

    const rowClass = computed(() => {
      return {
        'table-danger': isRowExpired.value,
        'text-muted': props.testWorkQueue?.state?.slug === states.COMPLETED,
        'text-success': props.testWorkQueue?.state?.slug === states.PACKAGED,
        'text-violet': props.testWorkQueue?.state?.slug === states.BAGGED,
        'border-violet-bottom': props.index === (props.groupLength -1)
      };
    });

    return { dateFormatter, getStatusImage, isRowExpired, rowClass, goToConsulting };
  }
};

</script>

<template>
  <tr :class="[rowClass]">
    <td v-if="index === 0" :rowspan="groupLength" class="clinic-cell text-dark">{{ clinic.name }}</td>
    <td
      @click="goToConsulting(testWorkQueue?.prescription_test?.prescription?.id, true);"
      class="cp"
      >
      <img v-if="testWorkQueue.state.slug" :src="getStatusImage" :width="25" />
      {{ testWorkQueue?.prescription_test?.prescription?.number_text }}
    </td>
    <td>
      {{ testWorkQueue?.prescription_test?.device?.name}}
    </td>
    <td>
      {{ testWorkQueue?.prescription_test?.prescription?.customer?.first_name }}
      {{ testWorkQueue?.prescription_test?.prescription?.customer?.last_name }}
    </td>
    <td>{{ testWorkQueue?.test_work_queue_phases[0]?.internal_phase?.department?.name ?? 'Logistica' }}</td>
    <td>{{ dateFormatter(testWorkQueue?.delivery_date) }}</td>
    <td>{{ dateFormatter(testWorkQueue?.prescription_test?.date?.start_at) }}</td>
    <td v-if="index === 0" :rowspan="groupLength" class="clinic-cell">
      <button v-if="$props.printButton" type="button" class="btn btn-violet text-uppercase" @click="() => $emit('print')">
        <img src="@/assets/icons/printer-white.svg" width="20" alt="print">
        stampa ddt
      </button>
    </td>
  </tr>
</template>


<style scoped>
.clinic-cell {
  font-weight: bold;
  background-color: #f3eaf7;
  text-align: center;
  vertical-align: middle;
  max-width: 15rem;
}
.table-danger {
  background-color: #f8d7da !important;
}
.border-violet-bottom {
  border-bottom: 1rem solid #E1D2F2 !important
}
</style>
