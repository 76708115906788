import { ref, computed } from 'vue';
import _keyBy from "lodash/keyBy";
import _map from "lodash/map";
import _find from "lodash/find";
import _head from "lodash/head";
import _assign from "lodash/assign";
import _cloneDeep from "lodash/cloneDeep";

export function useInjectParentAndSortByState() {

  const testWorkQueuePhases = ref([])

  const structuredPhases = computed(() => {
    const clonedPhases = _cloneDeep(testWorkQueuePhases.value)

    return injectParentAndSortByState(clonedPhases);
  })

  const injectParentAndSortByState = (testWorkQueuePhases) => {

    const sortedPhases = [];

    const idToPhaseMap = _keyBy(testWorkQueuePhases, "id");

    function sortRecursively(phase) {

      const { parent_test_work_queue_phases } = phase;

      const parentId = parent_test_work_queue_phases.length === 0
        ? null
        : parent_test_work_queue_phases[0].id;

      if (parentId) {
        const parentPhase = idToPhaseMap[parentId];
        sortRecursively(parentPhase);
      }

      phase.parent = parentId ? { ...idToPhaseMap[parentId].state, id: parentId } : null;
    }

    testWorkQueuePhases.forEach(phase => sortRecursively(phase));

    if (testWorkQueuePhases.length > 1) testWorkQueuePhases.forEach((phase) => addChildAndState(phase))

    function addChildAndState(item) {
      if (item.parent) {
        _find(testWorkQueuePhases, (phase) => {
          return phase.id == item.parent.id }).child = { id: item.id, slug: item.state?.slug, created_at: item.state?.created_at }
      }
    }

    const visited = {};

    function visit(phase) {
      if (! visited[phase.id]) {
        if (phase.parent) {
          visit(idToPhaseMap[phase.parent.id]);
        }
        visited[phase.id] = true;
        sortedPhases.push(phase);
      }
    }

    testWorkQueuePhases.forEach((phase) => visit(phase));

    return _map(sortedPhases, (phase) => {
      const { ...phaseData } = phase;

      return { ...phaseData, parent: phase.parent };
    });
  }

  const pushIsoPhase = (isoPhase, allPhasesComnpleted) => {

      const isoPhaseParent = isoPhase.parent_test_work_queue_phases?.length ? _head(isoPhase.parent_test_work_queue_phases).id : null;

      updateObjectByNestedId(testWorkQueuePhases.value, isoPhaseParent, { id: isoPhase.id }, allPhasesComnpleted);

      testWorkQueuePhases.value.push(isoPhase);
    }

    const updateObjectByNestedId = (twqps, parentId, updatedProperties, allPhasesComnpleted = false) => {
      const objectToUpdate = () => {
        if (parentId && allPhasesComnpleted)
          return _find(twqps, item => ! item.child)
        else if (parentId)
          return _find(twqps, (item) => _find(item.parent_test_work_queue_phases, { id: parentId }))
        else
          return _find(twqps, (item) => ! item.parent_test_work_queue_phases.length)
      }

      // if there are only one pending phase || if all phases.length are in pending || if all phases.length are completed
      if (! objectToUpdate().parent_test_work_queue_phases.length && ! allPhasesComnpleted || objectToUpdate().parent_test_work_queue_phases.length && allPhasesComnpleted)
        objectToUpdate().parent_test_work_queue_phases.push(updatedProperties);
      // if parent is completed and phase is pending || if there are only one phase completed;
      else _assign(_head(objectToUpdate().parent_test_work_queue_phases), updatedProperties);
    }

  return {
    testWorkQueuePhases,
    structuredPhases,
    injectParentAndSortByState,
    pushIsoPhase
  };
}
