<template>
  <div class="d-flex flex-column p-4 vw-100 vh-100">
    <div class="d-flex flex-column">
      <div class="d-flex mb-3">
        <!-- Btn back -->
        <button class="btn ps-0 text-secondary" @click="redirect()">
          <span class="d-flex align-items-center">
            <template v-if="isIsoProgettazione">
              <div class="btn btn-violet text-uppercase d-flex align-items-center">
                <img
                  class="me-2"
                  src="@/assets/icons/logout.svg"
                  width="30"
                  height="30"
                  alt="logout"
                />
                esci
              </div>
            </template>
            <template v-else>
              <button @click="redirect()"
                  class="btn rounded-btn-custom shadow bg-violet text-white mt-3">
                <div class="d-flex align-items-center">
                  <ChevronLeft color="white" class="me-2"/>
                  <span class="text-uppercase">Check-in</span>
                </div>
              </button>
            </template>
          </span>
        </button>
      </div>

      <div class="row">
        <!-- Brand -->
        <div class="brand col-sm-12 col-md-12 col-lg-12 col-xl-2">
          <div class="brand_container d-flex">
            <Brand :width="180" withChat />
          </div>
        </div>

        <!-- Counters -->
        <div class="counters col-sm-12 col-md-12 col-lg-12 col-xl-10 mt-5">
          <div
            class="
              counters_container
              d-flex
              align-items-end
              flex-wrap
              justify-content-center
              h-100
              w-100
              px-2
            "
          >
            <!-- Actions -->
            <button type="button" class="btn btn-violet me-2" onclick="location.reload()">
              Aggiorna
            </button>
            <button type="button" class="btn btn-violet me-2"
              @click="openWrongPrescriptionModal()"
              v-if="isIsoProgettazione && stateActive === DENTAL_IMPRESSION">
              Trasforma in digitale
            </button>
            <button
              type="button"
              class="btn btn-violet"
              @click="$router.push({ name: 'SummaryManager' })"
            >
              Pending
            </button>

            <!-- Counters -->
            <template v-if="preselectState">
              <template v-if="isIsoProgettazione">
                <Counter
                  class="ms-2 mt-2"
                  :show-loading="spinnerDentalImpression"
                  :counter="dentalImpressionCounter"
                  :label="'Impronte Digitali'"
                  :stateSlug="DENTAL_IMPRESSION"
                  :color="'#BE6DB7'"
                  :preSelected="preselectState === DENTAL_IMPRESSION"
                  :active="stateActive === DENTAL_IMPRESSION"
                  :readOnlyMode="isReadonly(dentalImpressionCounter)"
                  @selected="getByType"
                />

                <Counter
                  class="ms-2 mt-2"
                  :show-loading="spinnerDentalImpression"
                  :counter="assignDataCounter"
                  :label="'Assegnate'"
                  :stateSlug="states.ASSIGNED"
                  :color="'#FFC107'"
                  :preSelected="preselectState === states.ASSIGNED"
                  :active="stateActive === states.ASSIGNED"
                  :readOnlyMode="isReadonly(assignDataCounter)"
                  @selected="getByType"
                />
              </template>
              <Counter
                  class="ms-2 mt-2"
                  :show-loading="spinnerWarningInfo"
                  :counter="warningInfoDataCounter"
                  label="Info clinica / Aiuto"
                  :stateSlug="states.ON_HOLD"
                  :color="'#AF0B55'"
                  :preSelected="preselectState === states.ON_HOLD"
                  :active="stateActive === states.ON_HOLD"
                  :readOnlyMode="isReadonly(warningInfoDataCounter)"
                  @selected="getByType"
                />
              <template v-if="managerIso === false">
                <Counter
                  class="ms-2 mt-2"
                  :show-loading="spinnerInTheWorks"
                  :counter="inTheWorksDataCounter"
                  :label="'In lavorazione'"
                  :stateSlug="states.IN_THE_WORKS"
                  :color="'#9D9D9D'"
                  :preSelected="preselectState === states.IN_THE_WORKS"
                  :readOnlyMode="isReadonly(inTheWorksDataCounter)"
                  :active="stateActive === states.IN_THE_WORKS"
                  @selected="getByType"
                  />
                <Counter
                  class="ms-2 mt-2"
                  :show-loading="spinnerAssign"
                  :counter="assignDataCounter"
                  :label="'Assegnate'"
                  :stateSlug="states.ASSIGNED"
                  :color="'#FFC107'"
                  :preSelected="preselectState === states.ASSIGNED"
                  :active="stateActive === states.ASSIGNED"
                  :readOnlyMode="isReadonly(assignDataCounter)"
                  @selected="getByType"
                  />
              </template>
              <template v-if="canShowExternalWorks">
                <Counter
                  class="ms-2 mt-2"
                  :show-loading="spinnerSendToExternal"
                  :counter="sendToExternalDataCounter"
                  :label="'Esterne'"
                  :stateSlug="states.SENT_TO_EXTERNAL"
                  :color="'#E08231'"
                  :preSelected="preselectState === states.SENT_TO_EXTERNAL"
                  :active="stateActive === states.SENT_TO_EXTERNAL"
                  :readOnlyMode="isReadonly(sendToExternalDataCounter)"
                  @selected="getByType"
                  />
              </template>
              <Counter
                class="ms-2 mt-2"
                :show-loading="spinnerFailed"
                :counter="failedDataCounter"
                :label="'Fallite'"
                :stateSlug="failedState"
                :color="'#E97B86'"
                :preSelected="preselectState === stateActive"
                :active="failedState === stateActive"
                :readOnlyMode="isReadonly(failedDataCounter)"
                @selected="getByType"
                />
            </template>
          </div>
        </div>
      </div>

      <!-- Consulting btn -->
      <Button label="consultazione" style="right: 50px" route-name="ConsultingSearch">
        <img class="me-2" src="@/assets/icons/search-white.svg" width="20" alt="bx-search" />
      </Button>
    </div>

    <div class="table h-80 mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- Table -->
      <div class="d-flex flex-column h-100">
        <div class="card shadow rounded-only-top border-0">
          <template v-if="stateActive === DENTAL_IMPRESSION">
            <GlobalSearch
              class="py-3"
              :placeholder="'Ricerca per CODICE o Paziente'"
              @toSearch="readValueToSearch"
            />
          </template>
          <template v-else>
            <GlobalSearch
              :disabled="disabledInputSearch"
              :debounce-time="1000"
              :min-characters="MIN_CHARACTERS_FOR_SEARCH"
              class="py-3"
              :placeholder="'Ricerca per CODICE o Paziente'"
              @toSearch="readValueToSearch"
            />
          </template>
        </div>

        <div class="card border-0 shadow rounded-only-bottom table-responsive h-100">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th v-for="(title, index) in header.filter((label) => label !== 'moreInfo')"
                  :key="index"
                  scope="col">
                  {{ title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, indexRow) in dataView" :key="indexRow">
                <tr
                  :class="dataView[indexRow].moreInfo.stateSlug"
                  @click="details(row.moreInfo)"
                >
                  <template v-for="(value, indexValue) in Object.values(row)" :key="indexValue">
                    <template v-if="header[indexValue] !== 'moreInfo'">
                      <template v-if="header[indexValue] === 'prestazione'">
                        <td class="text-uppercase">
                          <template v-if="getPrescriptionRowName(value) !== ''">
                            {{ getPrescriptionRowName(value) }}
                            (<Render :data="groupedByBridgesAndTooth(value)" />)
                          </template>
                        </td>
                      </template>
                      <template v-else-if="header[indexValue] === 'fornitore'">
                        <td>
                          <Vendors
                            @click.stop
                            label-btn="conferma"
                            :callback="storeVendorId"
                            :test-work-queue-phase-id="row.moreInfo.test_work_queue_phase_id"
                            :vendors="vendors"
                          />
                        </td>
                      </template>
                      <template v-else>
                        <td class="text-uppercase" :class="{'text-start': header[indexValue] === 'nota motivazione'}">
                          <small class="badge bg-warning text-uppercase"
                            v-if="possibleZebras.includes(value)">
                            zebra
                          </small>
                          {{ value }}
                        </td>
                      </template>
                    </template>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>

          <template v-if="data.length === 0">
            <div class="d-flex w-100 h-100 justify-content-center align-items-center p-5">
              <h3>Nessuna lavorazione presente</h3>
            </div>
          </template>
        </div>

        <template v-if="totalPages !== null">
          <Pagination
            class="d-flex justify-content-end"
            :total-pages="totalPages"
            :page-number="page"
            :array="dataView"
            @increment="() => page++"
            @decrement="() => page--"
          />
        </template>
      </div>
    </div>
  </div>
  <Standard v-if="showWrongPrescriptionModal"
    size="md"
    modal_id="wrongPrescriptionModal"
    >
    <div>
      <div class="p-1 border-0 text-center">
        <h4 class="mb-4 mt-2 fw-normal">Prescrizione da trasformare in digitale</h4>
      </div>
      <GlobalSearch
        class="m-3"
        placeholder="Ricerca Prescrizione Errata"
        :debounceTime="1500"
        :minCharacters="3"
        :reset="clearInput"
        ref="isoProgettazioneSearch"
        @toSearch="readValueWrongPrescription">
      </GlobalSearch>
      <div class="p-2" v-if="wrongPrescriptionSearched">
        <div class="d-flex flex-column">
          <div class="ms-2 text-wrap fs-6 text-uppercase">
            <div class="text-uppercase mb-3 text-start">
                <span>prescrizione: </span>
                <span class="text-violet">{{ wrongPrescriptionSearched.number_text }}</span>
              </div>
            <div class="text-uppercase mb-3 text-start">
                <span>paziente: </span>
                <span class="text-violet">{{ `${wrongPrescriptionSearched.customer.first_name} ${wrongPrescriptionSearched.customer.last_name}` }}</span>
              </div>
              <div class="text-uppercase mb-3 text-start">
                <span>data appuntamento: </span>
                <span class="text-violet">{{ moment(wrongPrescriptionSearched.current_test.date.start_at).format('DD/MM/YYYY HH:mm') }}</span>
              </div>
              <div class="text-uppercase mb-3 text-start">
                <span>data creazione: </span>
                <span class="prescription-row text-violet">{{ moment(wrongPrescriptionSearched.current_test.created_at).format('DD/MM/YYYY HH:mm') }}</span>
              </div>
          </div>
        </div>
      </div>
      <div class="p-4 d-flex justify-content-center">
        <button
        v-if="wrongPrescriptionSearched"
        type="button" class="btn btn-violet me-2"
        @click="sendOnDigitalCheckin(wrongPrescriptionSearched.current_test.id)">
        Rendi digitale
      </button>
      <button
        type="button" class="btn btn-violet me-2"
        @click="hideWrongPrescriptionModal()">
        Annulla
      </button>
      </div>
    </div>
  </Standard>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
  toRaw,
  inject,
  watch,
  nextTick,
} from 'vue';
import _head from 'lodash/head';
import _filter from 'lodash/filter';
import _ from 'lodash';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { success } from '@/use/toast/success';
import { error } from '@/use/toast/error';
import {hideModal} from '@/use/modal/hide';

import Brand from '@/components/Brand';
import Counter from '@/components/Manager/Counter';
import Render from '@/components/PrescriptionRows/Render.vue';
import GlobalSearch from '@/components/GlobalSearch';
import Button from '@/components/general/Button';
import Pagination from '@/components/general/Pagination.vue';
import Vendors from '@/components/Manager/Vendors.vue';
import Standard from '@/components/Modal/Empty';
import ChevronLeft from '../../components/Icons/ChevronLeft.vue';

import { fetchByDepartmentSlug as getDataBySlugs } from '@/use/repositories/testWorkQueuePhase/fetchByDepartmentSlug';
import { groupedByBridgesAndTooth } from '@/use/repositories/prescriptionRows/groupedByBridgesAndTooth';
import { fetchByRole as fetchVendors } from '@/use/repositories/users/fetchByRole';
import { externalPhases } from '@/use/repositories/testWorkQueuePhase/externalPhases';
import { update as updateTestWorkQueuePhase } from '@/use/repositories/testWorkQueuePhase/update';
import { fetchDentalImpression } from '@/use/repositories/prescriptions/fetchDentalImpression';
import { store as createTestWorkQueue } from '@/use/repositories/testWorkQueue/store';
import { update as updateTestWorkQueueState } from '@/use/repositories/testWorkQueue/update';
import { update as updatePrescriptionTest } from "@/use/repositories/prescriptionTests/update";
import { store as storeCrossCheckin } from "@/use/repositories/crossCheckin/store";
import { searchBy as searchByNumberText } from "@/use/repositories/prescriptions/searchBy";
import { fetchById as validatePrescriptionTest } from "@/use/repositories/prescriptionTests/fetchById";

import managerCounters from '@/use/manager/useCounters';

export default {
  name: 'Dashboard',
  components: {
    Vendors,
    Counter,
    Brand,
    Render,
    GlobalSearch,
    Button,
    Pagination,
    Standard,
    ChevronLeft,
},
  setup() {
    const i18n = useI18n();
    const { states, prescriptionTestStates } = inject("constants");
    const { departments } = inject('constants');
    const store = useStore();
    const router = useRouter();
    const data = ref([]);
    const searchTerm = ref('');
    const wrongPrescriptionTerm = ref("");
    const showWrongPrescriptionModal = ref(false);
    const wrongPrescriptionSearched = ref(null);
    const clearInput = ref(false);

    const sendOnDigitalCheckin = (prescriptionTestId) => {
      updatePrescriptionTest(
        prescriptionTestId,
        {
          action: "update_intraoral_scanner",
          intraoral_scanner_id: 1,
        },
        true
      )
        .then(() => {
          updatePrescriptionTest(
            prescriptionTestId,
            {
              action: "change_state",
              state_slug: prescriptionTestStates.INVIATA_AL_LAB,
            },
            true
          ).then(() => {
            storeCrossCheckin(prescriptionTestId, "to-digital").then(() => {
              success(i18n.t("Processing postponed to check-in"));
            });
          });
        })
        .finally(() => {
          reset(wrongPrescriptionTerm, wrongPrescriptionSearched);
          setTimeout(() => {
            location.reload()
          }, 5000);
        });
    }

    const hideWrongPrescriptionModal = () => {
      hideModal('wrongPrescriptionModal');
      showWrongPrescriptionModal.value = false;
      reset(wrongPrescriptionTerm, wrongPrescriptionSearched);
    }

    const validatePrescriptionForCrossCheckin = (prescriptionTest) => {
      let errorMessage = null;

      // Validate if prescription is not already in lab
      if (prescriptionTest.test_work_queue !== null) {
        errorMessage = i18n.t("Prescription already in lab");
      }

      if (prescriptionTest.intraoral_scanner_id !== null) {
        errorMessage = i18n.t("Prescription already digital");
      }

      // Validate date
      if (moment(prescriptionTest.date.start_at).isBefore(moment())) {
        errorMessage = i18n.t("Prescription has invalid date");
      }

      return errorMessage;
    }

    const readValueWrongPrescription = (value) => {
      searchByNumberText(value.trim().toUpperCase(), 'by_prescription_with_current_test').then((response) => {
        if (response.prescriptions.data.data.length) {
          const prescription = _head(response.prescriptions.data.data);
          const validate = validatePrescriptionForCrossCheckin(prescription.current_test);

          if (validate == null) {
            wrongPrescriptionSearched.value = prescription;
          } else {
            reset(wrongPrescriptionTerm, wrongPrescriptionSearched);
            error(i18n.t(validate));
          }
        } else {
          reset(wrongPrescriptionTerm, wrongPrescriptionSearched);
          error(i18n.t("Prescription not found"));
        }
      }).finally(() => clearInput.value = false);
    };

    const openWrongPrescriptionModal = () => {
      showWrongPrescriptionModal.value = true;
    }

    const reset = async (string, entity) => {
      string.value = "";
      entity.value = null;
      clearInput.value = true;
    };

    const isReadonly = (counter) => {
      return (counter === 0);
    }
    const dataView = computed(() => {
      if (searchTerm.value.length > 0 && stateActive.value === DENTAL_IMPRESSION.value) {
        // Local all record set.
        return _filter(data.value, (prescription) => {
          // Check if the query matches the prescription code or patient name
            return (
            _.toString(prescription.codice).includes(searchTerm.value.toLowerCase()) ||
            _.toLower(prescription.paziente).includes(_.toLower(searchTerm.value.toLowerCase()))
          );
        });
      } else {
        return isIsoProgettazione.value ? data.value : _.uniqBy(data.value, "moreInfo.test_work_queue_phase_id");
      }

    });
    const recordSet = ref([]);
    const isIsoProgettazione = ref(
      store.state.mainRole.team.name === departments.ISO_PROGETTAZIONE
    );
    const {
      spinnerWarningInfo,
      spinnerInTheWorks,
      spinnerAssign,
      spinnerSendToExternal,
      spinnerFailed,
      warningInfoDataCounter,
      inTheWorksDataCounter,
      assignDataCounter,
      sendToExternalDataCounter,
      failedDataCounter,
      manageManagerCounters,
      canShowExternalWorks,
      hasManagerRole,
      isIso,
    } = managerCounters();
    const disabledInputSearch = ref(false);
    const managerIso = isIso && hasManagerRole;
    const failedState = managerIso ? states.ISO_FAILED : states.MANAGER_FAILED;
    const DENTAL_IMPRESSION = ref('dental-impression');
    const MIN_CHARACTERS_FOR_SEARCH = 4;
    const preselectState = ref(null);
    const spinnerDentalImpression = ref(true);
    const dentalImpressionCounter = ref(0);
    const stateActive = ref(null);
    const header = ref([]);
    const page = ref(1);
    const totalPages = ref(null);
    const possibleZebras= ref([]);
    // Pusher.
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const vendors = ref([]);
    const subscribe = (channelName, eventName) => {
      console.log(`subscribing from "${channelName}"...`, { $pusher: pusher });
      const channel = pusher.subscribe(channelName);
      channel.bind('pusher:subscription_succeeded', () => console.log('subscription succeeded'));
      channel.bind(eventName, async (event) => {
        console.log('event received', event);
        manageManagerCounters(event.state.slug, 'SUM');
      });
    };
    const vendorRequest = computed(() => {
      let sentToExternalIsActive = stateActive.value === states.SENT_TO_EXTERNAL;
      if (sentToExternalIsActive) {
        getVendors();
      }

      return sentToExternalIsActive;
    });
    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, { $pusher: pusher });
      console.log('unsubscribing...');
      pusher.unsubscribe(channelName);
    };

    onMounted(() => {
      subscribe(`presence-user.${store.state.user.id}`, 'manager_change_phase_state');
      clearInfoPaginate();
      if (isIsoProgettazione.value) {
        preselectState.value = DENTAL_IMPRESSION.value;
      } else {
        preselectState.value = states.ON_HOLD;
      }
    });

    onUnmounted(() => {
      console.log('unmount');
      unsubscribeChannel(`presence-user.${store.state.user.id}`);
    });


    const searchByKeyword = (keyword) => {
      disabledInputSearch.value = true;
      getDataBySlugs(
        store.state.mainRole.team.name,
        typeof stateActive.value === 'object' ? stateActive.value : [stateActive.value],
        'by_department_and_states',
        null,
        keyword.toUpperCase()
      )
        .then((response) => {
          console.log(response.departmentTestWorkQueuePhases.data);
          response.departmentTestWorkQueuePhases.data.forEach((testWorkQueuePhase) => {
            recordSet.value.push(createTestWorkQueuePhaseStructure(testWorkQueuePhase));
          });
          data.value = recordSet.value;
          if (data.value.length) {
            setHeader();
            orderByDeliveryDate();
          }
        })
        .finally(() => {
          disabledInputSearch.value = false;
        });
    };

    const setFocusOninputSearch = () => {
      nextTick(() => {
        const input = document.getElementById('global-search');
        if (input !== null) {
          input.focus();
        }
      });
    };

    watch(() => disabledInputSearch.value, (value) => {
        if (value === false) setFocusOninputSearch();
      }
    );

    watch(() => searchTerm.value, (value) => {
        if (stateActive.value !== DENTAL_IMPRESSION.value) {
          if (value.length >= MIN_CHARACTERS_FOR_SEARCH) {
            clearData();
            searchByKeyword(value);
          }
          if (value.length === 0) {
            clearData();
            getData();
          }
        }
      }
    );

    const redirect = () => {
      if (isIsoProgettazione.value) return router.push({ name: "Logout" });

      return router.push({ name: "Checkin" });
    };

    const getVendors = () => {
      fetchVendors('vendor', 'with_test_work_queue_phases').then((response) => {
        vendors.value = response.users.map((item) => {
          return {
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
          };
        });
      });
    };

    const orderByDeliveryDate = () => {
      data.value = _.orderBy(toRaw(data.value), (row) => row.uscita, ['asc']);
    };
    const orderByAppointmentDate = () => {
      data.value = _.sortBy(toRaw(data.value), (row) => moment(row['data appuntamento'], 'DD/MM/YYYY HH:mm'), 'asc');
    };

    const orderByPrescriptionTestCreatedDate = () => {
      data.value = _.orderBy(toRaw(data.value), (row) => moment(row['data creazione'], 'DD/MM/YYYY HH:mm'), 'asc');
    };

    const orderByExitDate = () => {
      data.value = _.orderBy(toRaw(data.value), (row) => moment(row['uscita'], 'DD/MM/YYYY HH:mm'), 'asc');
    };

    const getPrescriptionRowName = (prescriptionRows) => {
      if (prescriptionRows !== undefined && prescriptionRows.length > 0) {
        const firstRow = _head(prescriptionRows);
        return firstRow.listRow?.name || firstRow.name;
      }
      return '';
    };

    const getFeedbackDescription = (testWorkQueuePhase) => {
      if (testWorkQueuePhase.feedbacks.length > 0) {
        return _head(testWorkQueuePhase.feedbacks)?.feedback?.description;
      }
      return '';
    };
    const getFeedbackNote = (testWorkQueuePhase) => {
      if (testWorkQueuePhase.feedbacks.length > 0) {
        return _head(testWorkQueuePhase.feedbacks).note;
      }
      return '';
    };

    const restoreNumberPage = () => (page.value = null);
    const restoreNumberTotalPage = () => (totalPages.value = null);
    const clearInfoPaginate = () => {
      restoreNumberPage();
      restoreNumberTotalPage();
    };
    const clearHeader = () => (header.value = []);
    const clearRecordSet = () => (recordSet.value = []);
    const clearData = () => {
      data.value = [];
      clearHeader();
      clearRecordSet();
    };

    watch(() => page.value, (value) => {
        if (value !== null) getDataByState();
      }
    );

    const setDataPaginate = (meta) => {
      page.value = meta.current_page;
      totalPages.value = meta.last_page;
    };

    const setHeader = () => {
      header.value = Object.keys(_head(data.value));
    };

    const getByType = (type) => {
      stateActive.value = type.stateSlug;
      clearInfoPaginate();
      getDataByState();
    };

    const getData = () => {
      getDataBySlugs(
        store.state.mainRole.team.name,
        typeof stateActive.value === 'object' ? stateActive.value : [stateActive.value],
        'by_department_and_states',
        page.value ?? 1
      ).then((response) => {
        setDataPaginate(response.departmentTestWorkQueuePhases.meta);
        response.departmentTestWorkQueuePhases.data.forEach((testWorkQueuePhase) => {
          recordSet.value.push(createTestWorkQueuePhaseStructure(testWorkQueuePhase));
        });
        data.value = recordSet.value;
        if (data.value.length) {
          setHeader();
          if (store.state.mainRole.team.name === 'iso-progettazione') return orderByPrescriptionTestCreatedDate();
          orderByExitDate();
        }
      });
    };

    const getDataByState = () => {
      clearData();
      if (stateActive.value === DENTAL_IMPRESSION.value) return getDentalImpression();
      getData();
    };

    const getDentalImpression = () => {
      fetchDentalImpression().then((response) => {

        const groupedByCustomer = _.chain(response.dentalImpressions)
          .groupBy('customer.id')
          .map((value, key) => ({
            customer: parseInt(key),
            clinics: Object.values(_.groupBy(value, 'clinic.id'))
          }))
          .value();

        groupedByCustomer.forEach((el) => {
          el.clinics.forEach((prescriptions) => {
            if (prescriptions.length > 1) {
              prescriptions.forEach((prescription) => {
                possibleZebras.value.push(prescription.number_text);
              });
            }
          });
        });

        response.dentalImpressions.forEach((dentalImpression) => {
          recordSet.value.push(createDentalImpressionStructure(dentalImpression));
        });

        dentalImpressionCounter.value = response.dentalImpressions.length;
        spinnerDentalImpression.value = false;
        data.value = recordSet.value;
        if (data.value.length) {
          setHeader();
          orderByAppointmentDate();
        }
      });
    };

    const createDentalImpressionStructure = (dentalImpression) => {
      let prescriptionTest = dentalImpression.current_test;
      let appointmentDate = prescriptionTest.date !== null ? moment(prescriptionTest.date.start_at).format('DD/MM/YYYY HH:mm') : "";

      return {
        codice: dentalImpression.number_text,
        scanner: prescriptionTest.intraoral_scanner.name,
        'data appuntamento': appointmentDate,
        'data creazione': moment(prescriptionTest.created_at).format('DD/MM/YYYY HH:mm'),
        clinica: dentalImpression.clinic.name,
        paziente: `${dentalImpression.customer.first_name} ${dentalImpression.customer.last_name}`,
        bruxista: dentalImpression.customer.bruxism,
        dottore: `${dentalImpression.doctor.first_name} ${dentalImpression.doctor.last_name}`,
        'tipo impianto': dentalImpression.plant_type,
        prestazione: dentalImpression.prescription_rows,
        'fase clinica': prescriptionTest.name,
        nota: prescriptionTest.notes,
        moreInfo: {
          prescription_id: dentalImpression.id,
          stateSlug: 'dental-impression',
          prescription_test_id: prescriptionTest.id,
          test_id: prescriptionTest.test_id,
          scannerName: prescriptionTest.intraoral_scanner.name,
          number_text: dentalImpression.number_text,
        },
      };
    };

    const createTestWorkQueuePhaseStructure = (testWorkQueuePhase) => {
      const testWorkQueueHasFeedbacks = [
        states.ON_HOLD,
        states.ISO_FAILED,
        states.MANAGER_FAILED,
      ].includes(stateActive.value);

      let testWorkQueue = testWorkQueuePhase.test_work_queue;
      let prescription = testWorkQueue.prescription;
      let prescriptionTest = testWorkQueue.prescription_test;
      let internalPhase = testWorkQueuePhase.internal_phase;
      let structure = {};

      if (isIsoProgettazione.value) {
        structure = {
          paziente: `${testWorkQueue.prescription.customer.first_name} ${testWorkQueue.prescription.customer.last_name}`,
          codice: prescription.number_text,
          uscita: moment(testWorkQueue.delivery_date).format('DD/MM/YYYY HH:mm'),
        };

        if (testWorkQueueHasFeedbacks) {
          structure['nota motivazione'] = getFeedbackNote(testWorkQueuePhase);
          structure.motivazione = getFeedbackDescription(testWorkQueuePhase);
        }
      } else {
        structure = {
          codice: prescription.number_text,
          uscita: moment(testWorkQueue.delivery_date).format('DD/MM/YYYY HH:mm'),
          tecnico: `${testWorkQueuePhase?.user?.first_name} ${testWorkQueuePhase?.user?.last_name}`,
          prestazione: prescription.prescription_rows,
          'fase clinica': prescriptionTest.test.name,
        };
      }

      structure.moreInfo = {
        feedbacks: testWorkQueuePhase.feedbacks,
        prescription_id: prescription.id,
        stateSlug: testWorkQueuePhase.state.slug,
        test_work_queue_phase_id: testWorkQueuePhase.id,
        internal_phase_id: internalPhase.id,
        test_work_queue_id: testWorkQueue.id,
        prescription_test_id: prescriptionTest.id,
        test_id: prescriptionTest.test.id,
        test_work_queue_phase_state_slug: testWorkQueuePhase.state.slug,
      };

      if (testWorkQueueHasFeedbacks && ! isIsoProgettazione.value) {
        structure.motivazione = getFeedbackDescription(testWorkQueuePhase);
        structure['nota motivazione'] = getFeedbackNote(testWorkQueuePhase);
      }

      if (vendorRequest.value) {
        structure['fornitore'] = testWorkQueuePhase.id;
      }

      return structure;
    };

    const details = async (moreInfo) => {
      if (stateActive.value === states.SENT_TO_EXTERNAL) return;

      if (moreInfo.scannerName !== undefined) {

        try {
          await validatePrescriptionTest(moreInfo.prescription_test_id, {
            action: "validate_checkin",
            is_digital: 1
          });
        } catch (error) {
          error(i18n.t(error));
          console.log(error);
        }

        try {
          // CREATE TWQ
          const createResponse = await createTestWorkQueue(moreInfo.prescription_test_id, 'digital_check_in');
          console.log('CREATE TWQ');

          if (createResponse.testWorkQueue.data.test_work_queue_phases?.length === 0) return error(i18n.t("Digital flow not defined!"));

          moreInfo.internal_phase_id = _.head(createResponse.testWorkQueue.meta.fork_tree).internal_phase_id;
          moreInfo.test_work_queue_phase_id = _.head(createResponse.testWorkQueue.data.test_work_queue_phases).id;
          moreInfo.test_work_queue_id = createResponse.testWorkQueue.data.id;
        } catch (error) {
          error(i18n.t("Check-in failed"));
          console.log('CREATE TWQ FAILED');
          return;
        }

        try {
          // ASSIGN TWQP
          await updateTestWorkQueuePhase({
            source_ids: [moreInfo.test_work_queue_phase_id],
            user_id: store.state.user.id,
            action: 'attach_user',
          });
          console.log('ASSIGN TWQP');
        } catch (error) {
          error(i18n.t("Job update failed"));
          console.log('UPDATE TWQ STATE FAILED');
          return;
        }

        try {
          // UPDATE TWQP STATE
          await updateTestWorkQueuePhase({
            source_ids: [moreInfo.test_work_queue_phase_id],
            state: states.IN_THE_WORKS,
            action: 'update',
          });
          console.log('UPDATE TWQP');
        } catch (error) {
          error(i18n.t("Job update failed"));
          console.log('UPDATE TWQ STATE FAILED');
          return;
        }

        try {
          // UPDATE TWQ STATE
          const updateQueueResponse = await updateTestWorkQueueState({
            id: moreInfo.test_work_queue_id,
            state_slug: states.IN_THE_WORKS,
            action: "update_state"
          });
          console.log('UPDATE TWQ');

          moreInfo.test_work_queue_id = updateQueueResponse.testWorkQueue.id;
          redirectRules(moreInfo);
        } catch (error) {
          error(i18n.t("Job update failed"));
          console.log('UPDATE TWQ STATE FAILED');
        }
      } else {
        redirectRules(moreInfo);
      }
    };

    const redirectRules = (moreInfo) => {
      let readOnlyMode =
        moreInfo.stateSlug === states.SENT_TO_EXTERNAL ||
        moreInfo.stateSlug === states.IN_THE_WORKS;

      if (moreInfo.stateSlug === states.ON_HOLD) {
        readOnlyMode = moreInfo.feedbacks.length
          ? Boolean(_head(moreInfo.feedbacks).feedback.types.some(item => item.slug === states.IN_THE_WORKS))
          : false;
      }

      if (isIsoProgettazione.value && stateActive.value === states.ASSIGNED) {
        updateTestWorkQueuePhase({
          source_ids: [moreInfo.test_work_queue_phase_id],
          state: states.IN_THE_WORKS,
          action: 'update',
        })
      }

      moreInfo.read_only = readOnlyMode;
      moreInfo.by_manager = true;

      router.push({
        name: 'ConsultingDashboard',
        params: {
          prescription_id: moreInfo.prescription_id,
        },
        query: moreInfo,
      });
    };

    const storeVendorId = (testWorkQueuePhaseId, vendorId) => {
      externalPhases(testWorkQueuePhaseId, false, vendorId).then(async () => {
        updateTestWorkQueuePhase({
          source_ids: [testWorkQueuePhaseId],
          state: 'completed',
          action: 'update',
        }).then(async () => {
          let index = data.value.findIndex(
            (item) => item.moreInfo.test_work_queue_phase_id === testWorkQueuePhaseId
          );
          if (index !== -1) {
            // Sync counter.
            manageManagerCounters(data.value[index].moreInfo.stateSlug, 'SUBTRACTION');
            // Remove row.
            data.value.splice(index, 1);
            // Show success message.
            success(i18n.t('Supplier successfully selected!'));
          }
        });
      });
    };

    const readValueToSearch = (value) => {
      searchTerm.value = value;
    };

    return {
      details,
      data,
      moment,
      groupedByBridgesAndTooth,
      getPrescriptionRowName,
      getFeedbackDescription,
      getFeedbackNote,
      getByType,
      vendors,
      storeVendorId,
      orderByDeliveryDate,
      redirect,
      readValueToSearch,
      dataView,
      inTheWorksDataCounter,
      sendToExternalDataCounter,
      warningInfoDataCounter,
      failedDataCounter,
      assignDataCounter,
      canShowExternalWorks,
      states,
      spinnerWarningInfo,
      spinnerInTheWorks,
      spinnerAssign,
      spinnerSendToExternal,
      spinnerFailed,
      stateActive,
      isIsoProgettazione,
      spinnerDentalImpression,
      dentalImpressionCounter,
      header,
      managerIso,
      page,
      totalPages,
      preselectState,
      DENTAL_IMPRESSION,
      hasManagerRole,
      failedState,
      disabledInputSearch,
      MIN_CHARACTERS_FOR_SEARCH,
      isReadonly,
      possibleZebras,
      showWrongPrescriptionModal,
      hideWrongPrescriptionModal,
      wrongPrescriptionTerm,
      readValueWrongPrescription,
      openWrongPrescriptionModal,
      wrongPrescriptionSearched,
      sendOnDigitalCheckin,
      hideModal,
      clearInput
    };
  },
};
</script>

<style scoped>
.card {
  border-radius: unset;
}
.btn {
  white-space: nowrap;
}
.table-responsive thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #7e6492;
  color: #ffffff;
  border: none;
  padding: 10px 5px;
}
tr td:hover {
  cursor: pointer;
}
tr td,
tr th {
  text-align: center;
  white-space: nowrap;
  padding: 15px;
}
tr th {
  text-transform: uppercase;
}
.warning,
.iso-failed,
.manager-failed {
  background-color: #e97b86;
}
.on-hold {
  background-color: #af0b55;
}

.info {
  background-color: #a58aba;
}
.in-the-works {
  background-color: #6b6b6b;
}
.assigned {
  background-color: #ffc107;
}
.sent-to-external {
  background-color: #e08231;
}
.dental-impression {
  background-color: #be6db7;
}
.warning,
.iso-failed,
.manager-failed,
.info,
.in-the-works,
.assigned,
.sent-to-external,
.on-hold,
.dental-impression {
  color: #ffffff !important;
}
@media (max-width: 768px) {
  .brand,
  .counters {
    padding: 10px 0;
  }
  .brand_container,
  .counters_container {
    justify-content: center;
  }
}
</style>
