<template>
    <div></div>
</template>

<script>
import { onMounted } from "vue";
import { me } from "@/use/repositories/auth/me";
import { handleLogin } from "@/use/utilities/handleLogin"
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    name: "Sso",
    setup() {
        const route = useRoute();
        const store = useStore();

        onMounted(async () => {
            localStorage.setItem("authToken", route.query.token);
            await store.dispatch("setUser", null);
            await store.dispatch("setMainRole", null);

            me().then(async (response) => {
                // Set auth token in local storage.
                localStorage.setItem("authToken", response.data.meta.token);

                await store.dispatch("setUser", response.data.data);

                await store.dispatch("setUserData", response.data.meta.roles);

                handleLogin();
            });
        });
    },
};
</script>
