<script>
import { onMounted, onUnmounted, watch, computed, getCurrentInstance } from 'vue';
import useCheckoutData from "@/composables/useCheckoutData";
import CheckoutTableRowGroup from "@/components/CheckoutTableRowGroup";

export default {
  components: { CheckoutTableRowGroup },
  props: {
    filters: { type: Object },
  },
  setup(props) {
    const headers = ['Clinic', 'Prescriptions', 'Tag', 'Customer', 'Department', 'Date', 'Appointment', 'Actions'];

    const {
      groupTestWorkQueues,
      handleIncomingTestWorkQueue,
      updateTestWorkQueueState,
      isFailed,
      fetchTestWorkQueues,
    } = useCheckoutData(props.filters);

    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const groupedTestWorkQueues = computed(() => groupTestWorkQueues());

    watch(
      () => props.filters.shippingProvider,
      () => fetchTestWorkQueues(),
      { deep: true }
    );

    onMounted(() => {
      const channelName = 'private-live';
      console.log(`subscribing from "${channelName}"...`, { $pusher: pusher });
      const channel = pusher.subscribe(channelName);

      channel.bind('pusher:subscription_succeeded', () => console.log('subscription succeeded'));
      channel.bind('test_work_queue_phase_change-state',
        async event => {
          console.log('test_work_queue_phase_change-state', event);
          await handleIncomingTestWorkQueue(event.test_work_queue_phase?.test_work_queue?.id, props.filters.shippingProvider)
        }
      );
      channel.bind('test_work_queue_change-state',
        ({ test_work_queue }) => {
          console.log('test_work_queue_change-state', test_work_queue);
          updateTestWorkQueueState(test_work_queue.id, test_work_queue.state)
        }
      );
    });

    onMounted(fetchTestWorkQueues);

    onUnmounted(() => {
      const channelName = 'private-live';
      console.log(`unsubscribing from "${channelName}"...`, { $pusher: pusher });
      pusher.unsubscribe(channelName);
    });

    return { groupedTestWorkQueues, isFailed, headers };
  },
};
</script>

<template>
  <div class="table-responsive">
    <table class="table m-0">
      <thead class="table-header bg-light-violet">
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="textcenter">{{ $t(header) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <CheckoutTableRowGroup
          v-for="(group) in groupedTestWorkQueues"
          :key="group.id"
          :group="group"
          :is-failed="isFailed"
          :filters="$props.filters"
          :colspan="headers.length" />
      </tbody>
    </table>
  </div>
</template>

